import Utils from '../utils/Utils';
import { SFX, UIImages } from '../utils/Consts';

// noinspection JSVoidFunctionReturnValueUsed
class DialogueManager {
	constructor(scene, uiHandler, audioManager) {
		this.scene = scene;
		this.uiHandler = uiHandler;
		this.audioManager = audioManager;

		this.visible = false;
		this.defaultReadingSpeed = 200;
		this.readingBuffer = 2000;
		this.defaultNextDelay = 500;

		this.assetContainer = this.scene.add.container(0, 0);
		this.assetContainer.setDepth(Number.MAX_SAFE_INTEGER);
		this.queue = [];
		this.textBox();
	}

	addToQueue(text, audioKey, priority = 0, callback = null) {
		this.queue.push({
			text, audioKey, priority, callback,
		});
		this.queue.sort((a, b) => b.priority - a.priority);
	}

	processQueue() {
		if (this.visible) {
			return;
		}

		if (this.queue.length === 0) {
			this.visible = false;
			if (this.scene.animationManager.animationPlaying === false) { // Hacked
				this.scene.enableInput();
			}
			return;
		}
		this.scene.disableInput();
		this.visible = true;
		const { text, audioKey, callback } = this.queue.shift();
		this.playAudioAndManageDisplay(text, audioKey, callback);
	}

	playAudioAndManageDisplay(text, audioKey, callback) {
		this.textBoxAlternative();
		this.textBox.setVisible(this.visible);
		this.textBoxNext.setVisible(false);
		this.textBox.start(text, 50);

		if (audioKey) {
			this.audioManager.playDialogue(audioKey);
		}

		this.scene.time.delayedCall(this.defaultNextDelay, () => {
			this.textBoxNext.onFinishCallback = callback;
			this.textBoxNext.setVisible(true);
		});
	}

	textBoxAlternative() {
		// TODO remove reinit
		this.textBox = this.scene.rexUI.add.textBox({
			x: Utils.centerX() - 400,
			y: Utils.yPercent(22),
			width: 800,
			height: 100,
			background: this.scene.assetHandler.createImage(
				Utils.centerX() - 400,
				Utils.yPercent(22),
				UIImages.dialogBackground,
			),
			text: this.scene.rexUI.add.BBCodeText(0, 0, '', {
				fixedWidth: 760,
				fixedHeight: 90,
				fontFamily: 'Merriweather',
				fontSize: '26px',
				wrap: {
					mode: 'word',
					width: 700,
				},
				maxLines: 3,
			}).setDepth(9999),
			align: {
				text: 'left',
			},
			space: {
				left: 20,
				right: 20,
				top: 20,
				bottom: 20,
				text: 10,
			},
			typing: {
				wrap: false,
				speed: 333,
			},
		})
			.setOrigin(0)
			.setDepth(9998)
			.layout();

		this.assetContainer.add(this.textBox);

		this.textBoxNext = this.scene.uiHandler.createButton(
			Utils.xPercent(76),
			Utils.yPercent(11),
			UIImages.nextButton,
			() => {
				this.scene.audioManager.playOneShot(SFX.uiClick, 0.3);
				this.audioManager.skipDialogue();
				this.visible = false;
				this.textBox.setVisible(this.visible);
				this.textBoxNext.setVisible(this.visible);
				if (this.textBoxNext.onFinishCallback !== null && this.textBoxNext.onFinishCallback !== 'undefined') {
					this.textBoxNext.onFinishCallback();
				}
			},
			true,
		).setScale(0.5);
		this.assetContainer.add(this.textBoxNext);

		this.scene.tweens.add({
			targets: this.textBoxNext,
			scaleX: this.textBoxNext.scaleX * 1.1,
			scaleY: this.textBoxNext.scaleY * 1.1,
			duration: 1000,
			repeat: -1,
			yoyo: true,
			ease: 'Quad.easeInOut',
		});
	}

	textBox() {
		const hintTextBox = this.uiHandler.createImage(0, 0, 'textBox');
		hintTextBox.setDepth(9998);
		hintTextBox.setPosition(Utils.centerX(), Utils.yPercent(12));
		hintTextBox.setScale(600 / hintTextBox.width, 100 / hintTextBox.height);
		hintTextBox.setVisible(this.visible);

		const hintText = this.uiHandler.createText(370, Utils.yPercent(11));
		hintText.setDepth(9999);
		hintText.setVisible(this.visible);

		this.showTextBox = (text) => {
			this.visible = true;
			hintTextBox.setVisible(this.visible);
			hintText.setVisible(this.visible);
			hintText.setText(text);
		};

		this.hideTextBox = () => {
			this.visible = false;
			hintTextBox.setVisible(this.visible);
			hintText.setVisible(this.visible);
		};
	}
}

export default DialogueManager;
