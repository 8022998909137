/* global window */
import { Objects, SFX, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Texts from '../utils/Texts';
import Utils from '../utils/Utils';

class BookOverlay extends BaseOverlay {
	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager);
		this.cover = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.bookCover)
			.setScale(0.2);
		this.assetContainer.add(this.cover);

		this.page1 = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.bookPage1)
			.setScale(0.2);
		this.assetContainer.add(this.page1);
		this.page1Link = this.uiHandler.createButton(
			Utils.xPercent(41.5),
			Utils.yPercent(81.5),
			UIImages.link,
			() => window.open('https://nahlizenidokn.cuzk.cz/VyberBudovu/Stavba/InformaceO', '_blank'),
		).setScale(0.2);
		this.assetContainer.add(this.page1Link);

		this.page2 = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.bookPage2)
			.setScale(0.2);
		this.assetContainer.add(this.page2);
		this.page2Link = this.uiHandler.createButton(
			Utils.xPercent(80),
			Utils.yPercent(78.5),
			UIImages.link,
			() => window.open('https://seznat.justice.cz/', '_blank'),
		).setScale(0.2);
		this.assetContainer.add(this.page2Link);

		this.page3 = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.bookPage3)
			.setScale(0.2);
		this.assetContainer.add(this.page3);
		this.page4 = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.bookPage4)
			.setScale(0.2);
		this.assetContainer.add(this.page4);
		this.page4Link = this.uiHandler.createButton(
			Utils.xPercent(78.5),
			Utils.yPercent(81.5),
			UIImages.link,
			() => window.open('https://www.hlidacstatu.cz/', '_blank'),
		).setScale(0.2);
		this.assetContainer.add(this.page4Link);

		this.mark1 = this.uiHandler.createButton(
			Utils.xPercent(56),
			Utils.yPercent(12),
			UIImages.bookMark1,
			() => this.selectPage(this.page1),
		).setScale(0.2);
		this.assetContainer.add(this.mark1);

		this.mark2 = this.uiHandler.createButton(
			Utils.xPercent(63),
			Utils.yPercent(12),
			UIImages.bookMark2,
			() => this.selectPage(this.page2),
		).setScale(0.2);
		this.assetContainer.add(this.mark2);

		this.mark3 = this.uiHandler.createButton(
			Utils.xPercent(70),
			Utils.yPercent(11.5),
			UIImages.bookMark3,
			() => this.selectPage(this.page3),
		).setScale(0.2);
		this.assetContainer.add(this.mark3);

		this.mark4 = this.uiHandler.createButton(
			Utils.xPercent(77),
			Utils.yPercent(10.7),
			UIImages.bookMark4,
			() => this.selectPage(this.page4),
		).setScale(0.2);
		this.assetContainer.add(this.mark4);

		this.hide(false);
	}

	selectPage(selectedPage) {
		let selectedLink = null;
		this.audioManager.playOneShot(SFX.pageTurn);
		this.page1.setVisible(false);
		this.page1.disableInteractive();
		this.page1Link.setVisible(false);
		this.page1Link.disableInteractive();

		this.page2.setVisible(false);
		this.page2.disableInteractive();
		this.page2Link.setVisible(false);
		this.page2Link.disableInteractive();

		this.page3.setVisible(false);
		this.page3.disableInteractive();

		this.page4.setVisible(false);
		this.page4.disableInteractive();
		this.page4Link.setVisible(false);
		this.page4Link.disableInteractive();

		selectedPage.setVisible(true);
		selectedPage.setInteractive();

		// eslint-disable-next-line default-case
		switch (selectedPage) {
			case this.page1:
				selectedLink = this.page1Link;
				break;
			case this.page2:
				selectedLink = this.page2Link;
				break;
			case this.page4:
				selectedLink = this.page4Link;
				break;
		}

		if (selectedLink !== null) {
			selectedLink.setVisible(true);
			selectedLink.setInteractive();
		}
	}

	hide(hiddenByPlayer = true) {
		super.hide(hiddenByPlayer);
		this.cover.setVisible(this.visible);
		this.cover.disableInteractive();

		this.page1.setVisible(this.visible);
		this.page1.disableInteractive();
		this.page1Link.setVisible(this.visible);
		this.page1Link.disableInteractive();

		this.page2.setVisible(this.visible);
		this.page2.disableInteractive();
		this.page2Link.setVisible(this.visible);
		this.page2Link.disableInteractive();

		this.page3.setVisible(this.visible);
		this.page3.disableInteractive();

		this.page4.setVisible(this.visible);
		this.page4.disableInteractive();
		this.page4Link.setVisible(this.visible);
		this.page4Link.disableInteractive();

		this.mark1.setVisible(this.visible);
		this.mark1.disableInteractive();

		this.mark2.setVisible(this.visible);
		this.mark2.disableInteractive();

		this.mark3.setVisible(this.visible);
		this.mark3.disableInteractive();

		this.mark4.setVisible(this.visible);
		this.mark4.disableInteractive();

		// Closed for the first time, during Tutorial
		if (hiddenByPlayer && this.scene.gameState.bookClosed === false) {
			this.scene.gameState.bookFound = true;
			this.scene.gameState.bookClosed = true;
			this.scene.singleDialogLine(Texts.tutorialBookClosed, '04-tutorialBookClosed', 0);
			this.scene.objectsManager.appearAnimation(Objects.bookLocated);
		}
	}

	show() {
		super.show();
		this.audioManager.playOneShot(SFX.pageTurn);
		this.cover.setVisible(this.visible);
		this.cover.setInteractive();
		this.mark1.setVisible(this.visible);
		this.mark1.setInteractive();

		if (this.scene.gameState.bookPage2Found === true) {
			this.mark2.setVisible(this.visible);
			this.mark2.setInteractive();
		}

		if (this.scene.gameState.bookPage3Found === true) {
			this.mark3.setVisible(this.visible);
			this.mark3.setInteractive();
		}

		if (this.scene.gameState.bookPage4Found === true) {
			this.mark4.setVisible(this.visible);
			this.mark4.setInteractive();
		}

		if (this.scene.gameState.bookPage4Found === true) {
			this.page4.setVisible(this.visible);
			this.page4.setInteractive();
			this.page4Link.setVisible(this.visible);
			this.page4Link.setInteractive();
		} else if (this.scene.gameState.bookPage3Found === true) {
			this.page3.setVisible(this.visible);
			this.page3.setInteractive();
		} else	if (this.scene.gameState.bookPage2Found === true) {
			this.page2.setVisible(this.visible);
			this.page2.setInteractive();
			this.page2Link.setVisible(this.visible);
			this.page2Link.setInteractive();
		} else {
			this.page1.setVisible(this.visible);
			this.page1.setInteractive();
			this.page1Link.setVisible(this.visible);
			this.page1Link.setInteractive();
		}
	}
}

export default BookOverlay;
