/* eslint-disable lines-between-class-members */
// noinspection JSVoidFunctionReturnValueUsed
import Utils from '../utils/Utils';
import { Objects } from '../utils/Consts';
import CameraManager from './CameraManager';

class AnimationManager {
	static PRIMARY_ANIMATION = 'primary';
	static DOG_IDLE_MIN = 5;
	static DOG_IDLE_MAX = 25;
	static PLAYER_IDLE_MIN = 1;
	static PLAYER_IDLE_MAX = 10;

	dayAndNightAnimations = [];

	isClockDown = true;

	allowLights() {
		this.dayAndNightAnimations.forEach((object) => object.setPipeline('Light2D'));
	}

	disableLights() {
		this.dayAndNightAnimations.forEach((object) => object.resetPipeline());
	}

	constructor(scene) {
		this.scene = scene;
		this.animationPlaying = false;

		// --------------------- PREPARE ANIMATIONS ------------------------

		// Blackmail arrival
		this.blackmailPlane = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '06_vlastovka')
			.setVisible(false)
			.setActive(false);
		this.blackmailPlane.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('06_vlastovka', {
				prefix: 'pngs/vlastovka_',
				start: 0,
				end: 143,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// Teleport In
		this.teleportIn = this.scene.assetHandler.createSprite(Utils.xPercent(89), Utils.yPercent(18), 'teleportIn')
			.setScale(0.5)
			.setVisible(false)
			.setActive(false);
		this.teleportIn.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('teleportIn', {
				prefix: 'teleport_in/teleport_in',
				start: 0,
				end: 19,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// Teleport Out
		this.teleportOut = this.scene.assetHandler.createSprite(Utils.xPercent(89), Utils.yPercent(18), 'teleportOut')
			.setScale(0.5)
			.setVisible(false)
			.setActive(false);
		this.teleportOut.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('teleportOut', {
				prefix: 'teleport_out/teleport_out',
				start: 0,
				end: 19,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 01 Window night idle
		this.windowNightIdle = this.scene.assetHandler.createSprite(Utils.centerX() - Utils.xPercent(0.5), Utils.centerY(), '01_okno')
			.setVisible(false)
			.setActive(false);
		this.windowNightIdle.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('01_okno', {
				prefix: 'pngs/01_okno_',
				start: 25,
				end: 100,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: -1,
			hideOnComplete: true,
			showOnStart: true,
		});
		this.dayAndNightAnimations.push(this.windowNightIdle);

		// 01 Window day idle
		this.windowDayIdle = this.scene.assetHandler.createSprite(Utils.centerX() - Utils.xPercent(0.5), Utils.centerY(), '01_okno')
			.setVisible(false)
			.setActive(false);
		this.windowDayIdle.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('01_okno', {
				prefix: 'pngs/01_okno_',
				start: 157,
				end: 249,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: -1,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 01 Window night to day
		this.windowNightToDay = this.scene.assetHandler.createSprite(Utils.centerX() - Utils.xPercent(0.5), Utils.centerY(), '01_okno')
			.setVisible(false)
			.setPipeline('Light2D')
			.setActive(false);
		this.windowNightToDay.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('01_okno', {
				prefix: 'pngs/01_okno_',
				start: 100,
				end: 200,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 01 Window day to night
		this.windowDayToNight = this.scene.assetHandler.createSprite(Utils.centerX() - Utils.xPercent(0.5), Utils.centerY(), '01_okno')
			.setVisible(false)
			.setActive(false);
		this.windowDayToNight.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('01_okno', {
				prefix: 'pngs/01_okno_',
				start: 200,
				end: 297,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 01 Window day to night 2 electric boogaloo
		this.windowDayToNight2 = this.scene.assetHandler.createSprite(Utils.centerX() - Utils.xPercent(0.5), Utils.centerY(), '01_okno-1')
			.setVisible(false)
			.setActive(false);
		this.windowDayToNight2.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('01_okno-1', {
				prefix: 'pngs/01_okno_',
				start: 298,
				end: 305,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 03 Main goal
		this.mainGoal = this.scene.assetHandler.createSprite(Utils.xPercent(53.5), Utils.yPercent(57), '03_hlavniOtazka')
			.setScale(0.5)
			.setVisible(false)
			.setActive(false);
		this.mainGoal.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('03_hlavniOtazka', {
				prefix: 'hlavniOtazka_',
				start: 0,
				end: 7,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 04a Proof highlight horizontal
		this.proofHighlightHorizontal = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '04a_proofHighlight_horizontal')
			.setVisible(false)
			.setActive(false);
		this.proofHighlightHorizontal.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('04a_proofHighlight_horizontal', {
				prefix: 'pngs/04_proof_highlight_square_',
				start: 0,
				end: 18,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 04b Proof highlight square
		this.proofHighlightSquare = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '04b_proofHighlight_square')
			.setVisible(false)
			.setActive(false);
		this.proofHighlightSquare.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('04b_proofHighlight_square', {
				prefix: 'pngs/04_proof_highlight_square_',
				start: 0,
				end: 18,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 04c Proof highlight vertical
		this.proofHighlightVertical = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '04c_proofHighlight_vertical')
			.setVisible(false)
			.setActive(false);
		this.proofHighlightVertical.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('04c_proofHighlight_vertical', {
				prefix: 'pngs/04_proof_highlight_vertical_',
				start: 0,
				end: 18,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 05 Trapdoor open
		this.playerTrapdoorOpen = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '05_StandaPadajiciKniha')
			.setVisible(false)
			.setPipeline('Light2D')
			.setActive(false);
		this.playerTrapdoorOpen.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('05_StandaPadajiciKniha', {
				prefix: 'pngs/05_StandaPadajiciKniha_',
				start: 0,
				end: 95,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 07 Letter split open
		this.letterSplitOpen = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '07_StandaObalkaSplit')
			.setVisible(false)
			.setActive(false);
		this.letterSplitOpen.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('07_StandaObalkaSplit', {
				prefix: 'pngs/07_obalkaSplit_',
				start: 0,
				end: 47,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 08 PC print
		this.pcPrint = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '08_print')
			.setVisible(false)
			.setActive(false);
		this.pcPrint.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('08_print', {
				prefix: 'pngs/08_printing_',
				start: 0,
				end: 130,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 09 Player drop in
		this.playerDropIn = this.scene.assetHandler.createSprite(Utils.xPercent(40), Utils.yPercent(58), '09_StandaDrop')
			.setVisible(false)
			.setScale(1.2)
			.setActive(false);
		this.playerDropIn.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('09_StandaDrop', {
				prefix: 'pngs/09_StandaDrop_',
				start: 0,
				end: 24,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 10 Outro
		this.playerOutro = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '10_StandaOutro')
			.setVisible(false)
			.setActive(false);
		this.playerOutro.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('10_StandaOutro', {
				prefix: 'pngs/30_StandaOutro_',
				start: 0,
				end: 199,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 14 Clock movement
		this.clockMovement = this.scene.assetHandler.createSprite(Utils.xPercent(72.6), Utils.yPercent(54), '14_clock')
			.setScale(0.5)
			.setVisible(true);
		this.clockMovement.anims.create({
			key: 'up',
			frames: this.scene.anims.generateFrameNames('14_clock', {
				prefix: 'zavazi/zavazi/kukacky_zavazi_',
				start: 0,
				end: 24,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 10,
			repeat: 0,
			hideOnComplete: false,
			showOnStart: true,
		});
		this.clockMovement.anims.create({
			key: 'down',
			frames: this.scene.anims.generateFrameNames('14_clock', {
				prefix: 'zavazi/vraceni zavazi/kukacky_zavazi_vraceni',
				start: 0,
				end: 24,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: false,
			showOnStart: true,
		});
		this.dayAndNightAnimations.push(this.clockMovement);

		// 14 Fan movement
		this.fanMovement = this.scene.assetHandler.createSprite(Utils.xPercent(45.6), Utils.yPercent(34.9), '13_fan')
			.setScale(0.5)
			.setVisible(false)
			.setActive(false);
		this.fanMovement.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('13_fan', {
				prefix: '0_vetrak/vetrak',
				start: 0,
				end: 9,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 17,
			repeat: -1,
			hideOnComplete: true,
			showOnStart: true,
		});
		this.dayAndNightAnimations.push(this.fanMovement);

		// 15 Dog idle
		this.dogIdle = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '15_pejsek');
		this.dogIdle.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('15_pejsek', {
				prefix: 'pngs/15_pejsek_',
				start: 0,
				end: 119,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: false,
			showOnStart: false,
		});
		this.dayAndNightAnimations.push(this.dogIdle);

		// 18 Player Business card pick up
		this.playerBusinessCardPickUp = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '18_StandaVizitka')
			.setVisible(false)
			.setPipeline('Light2D')
			.setActive(false);
		this.playerBusinessCardPickUp.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('18_StandaVizitka', {
				prefix: 'pngs/18_StandaVizitka_',
				start: 0,
				end: 69,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 19 Player ID card pick up
		this.playerIdPickUp = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '19_StandaPrukaz')
			.setVisible(false)
			.setActive(false);
		this.playerIdPickUp.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('19_StandaPrukaz', {
				prefix: 'pngs/19_StandaPrukaz_',
				start: 0,
				end: 74,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 20 Player letter pick up
		this.playerLetterPickUp = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '20_StandaObalka')
			.setVisible(false)
			.setActive(false);
		this.playerLetterPickUp.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('20_StandaObalka', {
				prefix: 'pngs/20_StandaObalka_25fps_',
				start: 0,
				end: 74,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 21 Player photo pick up
		this.playerPhotoPickUp = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '21_StandaFotka')
			.setVisible(false)
			.setActive(false);
		this.playerPhotoPickUp.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('21_StandaFotka', {
				prefix: 'pngs/21_StandaFotka_',
				start: 0,
				end: 49,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 22 Player blackmail pick up
		this.playerBlackmailPickUp = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '22_StandaVlastovka')
			.setVisible(false)
			.setActive(false);
		this.playerBlackmailPickUp.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('22_StandaVlastovka', {
				prefix: 'pngs/22_StandaVlastovka_',
				start: 0,
				end: 49,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 23 Player book pick up
		this.playerBookPickUp = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '23_StandaKniha')
			.setVisible(false)
			.setActive(false);
		this.playerBookPickUp.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('23_StandaKniha', {
				prefix: 'pngs/23_StandaKniha_',
				start: 0,
				end: 74,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 24 Player PC start
		this.playerPcStart = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '24_StandaPC')
			.setVisible(false)
			.setActive(false);
		this.playerPcStart.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('24_StandaPC', {
				prefix: 'pngs/24_StandaPC_',
				start: 25,
				end: 99,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 25 Player PC plug in
		this.playerPcPlug = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '25_StandaZapojeniPC')
			.setVisible(false)
			.setActive(false);
		this.playerPcPlug.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('25_StandaZapojeniPC', {
				prefix: 'pngs/25_StandaZapojeniPC_',
				start: 25,
				end: 99,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 26 Player Clock start
		this.playerClockStart = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '26_StandaHodiny')
			.setVisible(false)
			.setActive(false);
		this.playerClockStart.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('26_StandaHodiny', {
				prefix: 'pngs/26_StandaHodiny_',
				start: 0,
				end: 61,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 27 Player Diary pickup
		this.playerDiaryPickUp = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '27_StandaDenicek')
			.setVisible(false)
			.setPipeline('Light2D')
			.setActive(false);
		this.playerDiaryPickUp.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('27_StandaDenicek', {
				prefix: 'pngs/27_StandaDenicek_',
				start: 0,
				end: 69,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 28 Family tree pickup
		this.playerFamilyTreePickUp = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '28_StandaRodokmen')
			.setVisible(false)
			.setActive(false);
		this.playerFamilyTreePickUp.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('28_StandaRodokmen', {
				prefix: 'pngs/28_StandaRodokmen_',
				start: 0,
				end: 74,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 30 Player newspaper pickup
		this.playerNewspaperPickup = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '30_StandaNoviny')
			.setVisible(false)
			.setPipeline('Light2D')
			.setActive(false);
		this.playerNewspaperPickup.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('30_StandaNoviny', {
				prefix: 'pngs/24_StandaNoviny_',
				start: 25,
				end: 92,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 31 Player PC night
		this.playerPcNight = this.scene.assetHandler.createSprite(Utils.centerX(), Utils.centerY(), '31_StandaPC_Noc')
			.setVisible(false)
			.setPipeline('Light2D')
			.setActive(false);
		this.playerPcNight.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('31_StandaPC_Noc', {
				prefix: 'pngs/31_StandaPC_Noc_',
				start: 25,
				end: 42,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: 0,
			hideOnComplete: true,
			showOnStart: true,
		});

		// 32 Player Idle
		this.playerIdle = this.scene.assetHandler.createSprite(Utils.xPercent(40), Utils.yPercent(58), '32_StandaIdle')
			.setVisible(false)
			.setScale(1.2)
			.setActive(false);
		const idleAnim = this.playerIdle.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('32_StandaIdle', {
				prefix: 'pngs/32_StandaIdle_',
				start: 25,
				end: 49,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: -1,
			hideOnComplete: true,
			showOnStart: true,
		});

		// Repeat idle animation in random interval
		this.playerIdle.on(
			'animationrepeat',
			() => {
				idleAnim.repeatDelay = Utils.getRandomInt(
					AnimationManager.PLAYER_IDLE_MIN * 1000,
					AnimationManager.PLAYER_IDLE_MAX * 1000,
				);
				this.playerIdle.anims.stop();
				this.playerIdle.anims.play(AnimationManager.PRIMARY_ANIMATION);
			},
		);

		// 32 Player Idle Night
		this.playerIdleNight = this.scene.assetHandler.createSprite(Utils.xPercent(40), Utils.yPercent(58), '32_StandaIdle_Noc')
			.setVisible(false)
			.setPipeline('Light2D')
			.setScale(1.2)
			.setActive(false);
		const idleAnim2 = this.playerIdleNight.anims.create({
			key: AnimationManager.PRIMARY_ANIMATION,
			frames: this.scene.anims.generateFrameNames('32_StandaIdle_Noc', {
				prefix: 'pngs/33_StandaIdle_Noc_',
				start: 25,
				end: 49,
				zeroPad: 5,
				suffix: '.png',
			}),
			frameRate: 25,
			repeat: -1,
			hideOnComplete: true,
			showOnStart: true,
		});

		// Repeat idle animation in random interval
		this.playerIdleNight.on(
			'animationrepeat',
			() => {
				idleAnim2.repeatDelay = Utils.getRandomInt(
					AnimationManager.PLAYER_IDLE_MIN * 1000,
					AnimationManager.PLAYER_IDLE_MAX * 1000,
				);
				this.playerIdleNight.anims.stop();
				this.playerIdleNight.anims.play(AnimationManager.PRIMARY_ANIMATION);
			},
		);
	}

	playAnimation(gameObjectName, deleteGO, callback) {
		let animationObject = null;
		this.animationPlaying = true;
		this.scene.disableInput();

		switch (gameObjectName) {
			case Objects.book:
				animationObject = this.playerBookPickUp;
				break;
			case Objects.familyTree:
				animationObject = this.playerFamilyTreePickUp;
				break;
			case Objects.clock:
				animationObject = this.playerClockStart;
				break;
			case Objects.photograph:
				animationObject = this.playerPhotoPickUp;
				break;
			case Objects.id:
				animationObject = this.playerIdPickUp;
				break;
			case Objects.letter:
				animationObject = this.playerLetterPickUp;
				this.scene.assetHandler.assetContainer.bringToTop(this.playerLetterPickUp);
				break;
			case Objects.blackmail:
				animationObject = this.blackmailPlane;
				this.scene.assetHandler.assetContainer.bringToTop(this.blackmailPlane);
				break;
			case Objects.blackmailLocated:
				animationObject = this.playerBlackmailPickUp;
				break;
			case Objects.plug:
				animationObject = this.playerPcPlug;
				break;
			case Objects.computerLocated:
				animationObject = this.playerPcStart;
				this.scene.assetHandler.assetContainer.bringToTop(this.playerPcStart);
				break;
			case Objects.computerLocatedMail:
				animationObject = this.playerPcNight;
				this.scene.assetHandler.assetContainer.bringToTop(this.playerPcNight);
				break;
			case Objects.translation:
				break;
			case Objects.mail:
				break;
			case Objects.businessCard:
				animationObject = this.playerBusinessCardPickUp;
				this.dogIdle.setVisible(false);
				break;
			case Objects.newspaper:
				animationObject = this.playerNewspaperPickup;
				break;
			case Objects.trapdoor:
				animationObject = this.playerTrapdoorOpen;
				this.scene.assetHandler.assetContainer.bringToTop(this.playerTrapdoorOpen);
				break;
			case Objects.diary:
				animationObject = this.playerDiaryPickUp;
				break;
			case Objects.reply:
				break;
			case Objects.conversation:
				break;
			case 'outro':
				animationObject = this.playerOutro;
				this.scene.assetHandler.assetContainer.bringToTop(this.playerOutro);
				break;
			default:
				break;
		}

		if (animationObject !== null) {
			this.stopIdle(false, () => {
				// During the paper plane animation should player be visible
				if (animationObject === this.blackmailPlane) {
					this.playIdle();
				}
				if (deleteGO !== undefined && deleteGO !== null) {
					deleteGO();
				}

				animationObject.setActive(true);
				animationObject.anims.play(AnimationManager.PRIMARY_ANIMATION);
				animationObject.on('animationcomplete', () => {
					this.animationPlaying = false;
					this.scene.enableInput();
					this.playIdle();
					if (callback !== undefined && callback !== null) {
						callback();
					}

					if (!(animationObject === this.playerPcStart || animationObject === this.playerPcNight)) {
						animationObject.destroy();
					}
				});
			});
		}
	}

	initStaticAnimation() {
		this.scene.delayedExecute(
			Utils.getRandomInt(AnimationManager.DOG_IDLE_MIN, AnimationManager.DOG_IDLE_MAX),
			() => this.playDogIdleAnimation(),
		);
		this.stopWindowIdle();
		this.playWindowIdle();
		this.fanMovement.setActive(true);
		this.fanMovement.anims.play(AnimationManager.PRIMARY_ANIMATION);
		// this.windowNightToDay.anims.play(AnimationManager.PRIMARY_ANIMATION);
	}

	playDogIdleAnimation() {
		this.dogIdle.anims.play(AnimationManager.PRIMARY_ANIMATION)
			.once('animationcomplete', () => {
				this.scene.delayedExecute(
					Utils.getRandomInt(AnimationManager.DOG_IDLE_MIN, AnimationManager.DOG_IDLE_MAX),
					() => this.playDogIdleAnimation(),
				);
			});
	}

	playSingleClockMovement() {
		if (this.clockMovement.anims.isPlaying === true) {
			return;
		}

		this.clockMovement.anims.play(this.isClockDown === true ? 'up' : 'down').once('animationcomplete', () => {
			this.isClockDown = !this.isClockDown;
		});
	}

	playDropIn(callback = null) {
		this.playerDropIn.setActive(true);
		this.playerDropIn.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
			if (callback !== null) {
				callback();
			}
		});
	}

	playWindowIdle() {
		const idleAnim = this.scene.isNight() ? this.windowNightIdle : this.windowDayIdle;
		idleAnim.setActive(true);
		idleAnim.anims.play(AnimationManager.PRIMARY_ANIMATION);
	}

	stopWindowIdle() {
		this.windowDayIdle.anims.stop();
		this.windowDayIdle.setFrame(0);
		this.windowDayIdle.setVisible(false);
		this.windowNightIdle.anims.stop();
		this.windowNightIdle.setFrame(0);
		this.windowNightIdle.setVisible(false);
	}

	playIdle(teleport = false, callback = null) {
		const idleAnim = this.scene.isNight() ? this.playerIdleNight : this.playerIdle;
		idleAnim.setActive(true);
		this.scene.assetHandler.assetContainer.bringToTop(idleAnim); // Always play on top of objects
		if (teleport) {
			this.playTeleportIn(89, 18, () => {
				idleAnim.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
					if (callback !== null) {
						callback();
					}
				});
			});
		} else {
			idleAnim.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
				if (callback !== null) {
					callback();
				}
			});
		}
	}

	stopIdle(teleport = false, callback = null) {
		this.playerIdle.anims.stop();
		this.playerIdle.setFrame(0);
		this.playerIdle.setVisible(false);
		this.playerIdleNight.anims.stop();
		this.playerIdleNight.setFrame(0);
		this.playerIdleNight.setVisible(false);

		if (teleport) {
			this.playTeleportOut(89, 18, false, () => {
				if (callback !== null) {
					callback();
				}
			});
		}

		if (!teleport && callback !== null) {
			callback();
		}
	}

	playTeleportIn(x, y, rotated, callback = null) {
		this.scene.assetHandler.assetContainer.bringToTop(this.teleportIn); // Always play on top of objects
		this.teleportIn.setPosition(Utils.xPercent(x), Utils.yPercent(y));
		if (rotated) {
			this.teleportIn.flipX = true;
		} else {
			this.teleportIn.flipX = false;
		}
		this.teleportIn.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
			if (callback !== null) {
				callback();
			}
		});
	}

	playTeleportOut(x, y, rotated, callback = null) {
		this.teleportOut.setPosition(Utils.xPercent(x), Utils.yPercent(y));
		if (rotated) {
			this.teleportOut.flipX = true;
		} else {
			this.teleportOut.flipX = false;
		}
		this.teleportOut.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
			if (callback !== null) {
				callback();
			}
		});
	}

	showMainGoal(callback) {
		this.animationPlaying = true;
		this.mainGoal.setActive(true);
		this.mainGoal.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
			this.animationPlaying = false;
			if (callback !== null) {
				callback();
			}
		});
	}

	openLetter(callback) {
		this.animationPlaying = true;
		this.stopIdle();
		this.letterSplitOpen.setActive(true);
		this.letterSplitOpen.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
			this.playIdle(false, null);
			this.animationPlaying = false;
			if (callback !== null) {
				callback();
			}
		});
	}

	printReply(callback) {
		this.animationPlaying = true;
		this.pcPrint.setActive(true);
		this.pcPrint.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
			this.animationPlaying = false;
			callback();
		});
	}

	dayToNight(callback) {
		this.animationPlaying = true;
		const zoomPoint = this.scene.assetHandler.createImage(
			Utils.xPercent(25),
			Utils.yPercent(50),
			Objects.book,
		).setVisible(false);

		this.scene.delayedExecute(1, () => {
			CameraManager.zoomToObject(zoomPoint, this.scene.cameras.main, 2500, 2.5);
			this.scene.delayedExecute(2.5, () => {
				this.stopWindowIdle();
				this.scene.assetHandler.assetContainer.bringToTop(this.windowDayToNight);
				this.windowDayToNight.setActive(true);
				this.windowDayToNight2.setActive(true);
				this.windowDayToNight.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
					this.windowDayToNight2.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
						this.playWindowIdle();
						CameraManager.zoomReset(this.scene.cameras.main);
						callback();
						this.animationPlaying = false;
					});
				});
			});
		});
	}

	nightToDay(callback) {
		this.animationPlaying = true;
		const zoomPoint = this.scene.assetHandler.createImage(
			Utils.xPercent(25),
			Utils.yPercent(50),
			Objects.book,
		).setVisible(false);

		this.scene.delayedExecute(1, () => {
			CameraManager.zoomToObject(zoomPoint, this.scene.cameras.main, 2500, 2.5);
			this.scene.delayedExecute(2.5, () => {
				this.stopWindowIdle();
				this.scene.assetHandler.assetContainer.bringToTop(this.windowNightToDay);
				this.windowNightToDay.setActive(true);
				this.windowNightToDay.anims.play(AnimationManager.PRIMARY_ANIMATION).once('animationcomplete', () => {
					CameraManager.zoomReset(this.scene.cameras.main);
					callback();
					this.playWindowIdle();
					this.animationPlaying = false;
				});
			});
		});
	}
}

export default AnimationManager;
