import { Objects, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Utils from '../utils/Utils';

class BlackmailOverlay extends BaseOverlay {
	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager);
		this.blackmail = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.blackmailBasic)
			.setScale(0.25);
		this.assetContainer.add(this.blackmail);
		this.hide(false);
	}

	hide(hiddenByPlayer = true) {
		super.hide();
		this.blackmail.setVisible(this.visible);
		this.blackmail.disableInteractive();

		if (hiddenByPlayer && this.scene.gameState.blackmailFound === false) {
			this.scene.gameState.blackmailFound = true;
			this.scene.objectsManager.appearAnimation(Objects.blackmailLocated);
			this.scene.delayedExecute(2.5, () => this.scene.mainGoalAppearAnimation());
		}
	}

	show() {
		super.show();
		this.blackmail.setVisible(this.visible);
		this.blackmail.setInteractive();
	}
}

export default BlackmailOverlay;
