import PlayerControls from '../utils/PlayerControls';

class UiHandler {
	static styles = {
		DEFAULT: {
			fontFamily: 'Merriweather',
			fontSize: '16px',
			fill: '#fff',
		},
		DEFAULT_BLACK: {
			fontFamily: 'Merriweather',
			fontSize: '16px',
			fill: '#000',
		},
	};

	constructor(scene, assetContainer) {
		this.scene = scene;
		this.uiContainer = this.scene.add.container(0, 0);
		this.uiContainer.setDepth(Number.MAX_SAFE_INTEGER);
		this.scene.cameras.main.ignore(this.uiContainer);
		this.uiCamera = this.scene.cameras.add(0, 0, this.scene.cameras.main.width, this.scene.cameras.main.height, false, 'uiCamera');
		this.uiCamera.ignore(assetContainer);
	}

	addUiElement(element) {
		this.uiContainer.add(element);
	}

	createImage(x, y, image) {
		const imageElement = this.scene.add.image(x, y, image);
		this.addUiElement(imageElement);

		return imageElement;
	}

	createText(x, y, text) {
		const textElement = this.scene.add.text(x, y, text, UiHandler.styles.DEFAULT);
		this.addUiElement(textElement);

		return textElement;
	}

	createTextTest(x, y, text, style) {
		const textElement = this.scene.add.text(x, y, text, style);
		this.addUiElement(textElement);

		return textElement;
	}

	createSprite(x, y, spriteKey) {
		const spriteElement = this.scene.add.sprite(x, y, spriteKey);
		this.addUiElement(spriteElement);

		return spriteElement;
	}

	createButton(x, y, spriteKey, onClick, addAllStates = false) {
		const buttonElement = this.createSprite(x, y, spriteKey);
		UiHandler.makeInteractive(buttonElement, onClick);
		this.addUiElement(buttonElement);

		if (addAllStates === true) {
			buttonElement.on('pointerover', () => buttonElement.setTexture(`${spriteKey}Hover`));
			buttonElement.on('pointerdown', () => buttonElement.setTexture(`${spriteKey}Clicked`));
			buttonElement.on('pointerout', () => buttonElement.setTexture(spriteKey));
			buttonElement.on('pointerup', () => buttonElement.setTexture(spriteKey));
		}

		return buttonElement;
	}

	static makeInteractive(uiElement, onClick) {
		uiElement.setInteractive();
		if (onClick) {
			uiElement.on('pointerdown', () => {
				if (!PlayerControls.input) {
					return;
				}
				onClick();
			});
		}
	}
}

export default UiHandler;
