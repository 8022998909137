/* global document */
import naja from 'naja';
import netteForms from 'nette-forms';

import reportWebVitals from './reportWebVitals';

import './scss/index.scss';
import './js/components/copy';
import './js/components/export';
import './js/components/flash';
import './js/phaser';
import './forms';

naja.formsHandler.netteForms = netteForms;

document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
