import { Objects, SFX, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Utils from '../utils/Utils';
import Texts from '../utils/Texts';

class DiaryOverlay extends BaseOverlay {
	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager, true);
		this.diary = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.diaryBasic)
			.setScale(0.55);
		this.assetContainer.add(this.diary);
		this.diaryReverse = this.uiHandler
			.createImage(Utils.centerX(), Utils.centerY(), UIImages.diaryReverse)
			.setScale(0.55);
		this.assetContainer.add(this.diaryReverse);

		this.diaryOpen = this.uiHandler
			.createImage(Utils.xPercent(42), Utils.centerY(), UIImages.diaryOpen)
			.setScale(0.55);
		this.assetContainer.add(this.diaryOpen);

		this.unlockButton = this.uiHandler.createButton(
			Utils.xPercent(95),
			Utils.yPercent(74),
			UIImages.unlockButton,
			() => this.tryUnlock(),
			true,
		).setScale(0.5);
		this.assetContainer.add(this.unlockButton);

		// Unlock screen
		this.unlockContainer = this.scene.add.container(0, 0);
		this.unlockContainer.setDepth(Number.MAX_SAFE_INTEGER - 1);
		const lock = this.uiHandler.createImage(
			Utils.xPercent(50),
			Utils.yPercent(50),
			UIImages.lock,
		).setScale(0.75);
		this.unlockContainer.add(lock);

		this.number1 = this.uiHandler.createImage(
			Utils.xPercent(41),
			Utils.yPercent(38.5),
			UIImages.lock0,
		).setName(0).setScale(0.7);
		this.unlockContainer.add(this.number1);

		this.upButton1 = this.uiHandler.createButton(
			Utils.xPercent(41),
			Utils.yPercent(51),
			UIImages.changeNumberButtonUp,
			() => {
				this.audioManager.playOneShot(SFX.uiClick, 0.3);
				let val = parseInt(this.number1.name, 10);
				val += 1;
				if (val > 9) {
					val = 0;
				}
				this.number1.name = val;
				this.number1.setTexture(`lock${val}`);
			},
		);
		this.unlockContainer.add(this.upButton1);

		this.downButton1 = this.uiHandler.createButton(
			Utils.xPercent(41),
			Utils.yPercent(27),
			UIImages.changeNumberButtonDown,
			() => {
				this.audioManager.playOneShot(SFX.uiClick, 0.3);
				let val = this.number1.name;
				val -= 1;
				if (val < 0) {
					val = 9;
				}
				this.number1.name = val;
				this.number1.setTexture(`lock${val}`);
			},
		);
		this.unlockContainer.add(this.downButton1);
		// ----------------------------------------------------------
		this.number2 = this.uiHandler.createImage(
			Utils.xPercent(47),
			Utils.yPercent(38.5),
			UIImages.lock0,
		).setName(0).setScale(0.7);
		this.unlockContainer.add(this.number2);

		this.upButton2 = this.uiHandler.createButton(
			Utils.xPercent(47),
			Utils.yPercent(51),
			UIImages.changeNumberButtonUp,
			() => {
				this.audioManager.playOneShot(SFX.uiClick, 0.3);
				let val = parseInt(this.number2.name, 10);
				val += 1;
				if (val > 9) {
					val = 0;
				}
				this.number2.name = val;
				this.number2.setTexture(`lock${val}`);
			},
		);
		this.unlockContainer.add(this.upButton2);

		this.downButton2 = this.uiHandler.createButton(
			Utils.xPercent(47),
			Utils.yPercent(27),
			UIImages.changeNumberButtonDown,
			() => {
				this.audioManager.playOneShot(SFX.uiClick, 0.3);
				let val = this.number2.name;
				val -= 1;
				if (val < 0) {
					val = 9;
				}
				this.number2.name = val;
				this.number2.setTexture(`lock${val}`);
			},
		);
		this.unlockContainer.add(this.downButton2);
		// ----------------------------------------------------------
		this.number3 = this.uiHandler.createImage(
			Utils.xPercent(54),
			Utils.yPercent(38.5),
			UIImages.lock0,
		).setName(0).setScale(0.7);
		this.unlockContainer.add(this.number3);

		this.upButton3 = this.uiHandler.createButton(
			Utils.xPercent(54),
			Utils.yPercent(51),
			UIImages.changeNumberButtonUp,
			() => {
				this.audioManager.playOneShot(SFX.uiClick, 0.3);
				let val = parseInt(this.number3.name, 10);
				val += 1;
				if (val > 9) {
					val = 0;
				}
				this.number3.name = val;
				this.number3.setTexture(`lock${val}`);
			},
		);
		this.unlockContainer.add(this.upButton3);

		this.downButton3 = this.uiHandler.createButton(
			Utils.xPercent(54),
			Utils.yPercent(27),
			UIImages.changeNumberButtonDown,
			() => {
				this.audioManager.playOneShot(SFX.uiClick, 0.3);
				let val = this.number3.name;
				val -= 1;
				if (val < 0) {
					val = 9;
				}
				this.number3.name = val;
				this.number3.setTexture(`lock${val}`);
			},
		);
		this.unlockContainer.add(this.downButton3);
		// ----------------------------------------------------------
		this.number4 = this.uiHandler.createImage(
			Utils.xPercent(60),
			Utils.yPercent(38.5),
			UIImages.lock0,
		).setName(0).setScale(0.7);
		this.unlockContainer.add(this.number4);

		this.upButton4 = this.uiHandler.createButton(
			Utils.xPercent(60),
			Utils.yPercent(51),
			UIImages.changeNumberButtonUp,
			() => {
				this.audioManager.playOneShot(SFX.uiClick, 0.3);
				let val = parseInt(this.number4.name, 10);
				val += 1;
				if (val > 9) {
					val = 0;
				}
				this.number4.name = val;
				this.number4.setTexture(`lock${val}`);
			},
		);
		this.unlockContainer.add(this.upButton4);

		this.downButton4 = this.uiHandler.createButton(
			Utils.xPercent(60),
			Utils.yPercent(27),
			UIImages.changeNumberButtonDown,
			() => {
				this.audioManager.playOneShot(SFX.uiClick, 0.3);
				let val = this.number4.name;
				val -= 1;
				if (val < 0) {
					val = 9;
				}
				this.number4.name = val;
				this.number4.setTexture(`lock${val}`);
			},
		);
		this.unlockContainer.add(this.downButton4);

		this.unlockButton2 = this.uiHandler.createButton(
			Utils.xPercent(66),
			Utils.yPercent(38),
			UIImages.keyButton,
			() => {
				if (
					this.number1.name.toString() === '2'
					&& this.number2.name.toString() === '9'
					&& this.number3.name.toString() === '0'
					&& this.number4.name.toString() === '4'
				) {
					this.hide();
					this.audioManager.playOneShot(SFX.uiCorrect, 0.7);
					this.scene.gameState.diaryOpened = true;
					this.show();
				} else {
					this.audioManager.playOneShot(SFX.uiIncorrect);
				}
			},
			true,
		).setScale(0.6);
		this.unlockContainer.add(this.unlockButton2);

		this.hide(false);
	}

	hide(hiddenByPlayer = true) {
		super.hide();
		this.diary.setVisible(this.visible);
		this.diaryReverse.setVisible(this.visible);
		this.diaryOpen.setVisible(this.visible);
		this.unlockButton.setVisible(this.visible);
		this.unlockContainer.setVisible(this.visible);

		if (hiddenByPlayer && this.scene.gameState.diaryFound === false) {
			this.scene.gameState.diaryFound = true;
			this.scene.objectsManager.appearAnimation(Objects.diaryLocated);
		}
	}

	show() {
		this.isFront = true;
		super.show();
		if (this.scene.gameState.diaryOpened === true) {
			this.diaryOpen.setVisible(this.visible);
			this.unlockButton.setVisible(false);
			this.rotateButton.setVisible(false);
		} else {
			this.diary.setVisible(this.visible);
			this.unlockButton.setVisible(this.visible && this.scene.gameState.diaryOpened === false);
		}
	}

	rotate() {
		super.rotate();
		this.diary.setVisible(this.isFront === true);

		this.diaryReverse.setVisible(this.isFront === false);
		this.unlockButton.setVisible(this.isFront === true && this.scene.gameState.diaryOpened === false);
	}

	tryUnlock() {
		this.scene.audioManager.playOneShot(SFX.uiClick, 0.3);
		if (this.scene.gameState.diaryOpened === true) {
			return;
		}

		if (this.scene.canOpenDiary === false) {
			this.scene.audioManager.playOneShot(SFX.uiIncorrect);
			this.scene.singleDialogLine(Texts.secondDiaryNoUnlock, null, 0);
		} else {
			this.diary.setVisible(false);
			this.rotateButton.setVisible(false);
			this.unlockButton.setVisible(false);
			this.unlockContainer.setVisible(true);
			this.unlockContainer.setInteractive();
		}
	}
}

export default DiaryOverlay;
