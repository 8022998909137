import { SFX, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Utils from '../utils/Utils';
import Texts from '../utils/Texts';
import Hints from '../utils/Hints';

class ComputerOverlay extends BaseOverlay {
	invalidMailTries = 0;

	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager);
		// Login screen and inputs
		this.loginScreen = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.computerBasic)
			.setScale(1);
		this.assetContainer.add(this.loginScreen);

		this.loginButton = this.uiHandler.createButton(
			Utils.xPercent(57),
			Utils.yPercent(50.25),
			UIImages.computerBasicButton,
			() => this.tryPassword(),
		).setScale(1.2);
		this.assetContainer.add(this.loginButton);

		this.input = this.scene.add.rexInputText(Utils.xPercent(46), Utils.centerY(), 250, 60, {
			placeholder: 'Zadejte heslo',
			color: 'black',
			fontSize: '35px',
		});

		this.input.on('keydown', (inputText, e) => {
			if ((e.key === 'Enter')) {
				this.tryPassword();
			}
		});

		// Mail screen and dropdowns
		this.mailContainer = this.scene.add.container(0, 0);
		this.mailContainer.setDepth(Number.MAX_SAFE_INTEGER - 1);
		this.mailScreen = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.computerMail)
			.setScale(1);
		this.mailContainer.add(this.mailScreen);

		this.mailInput = this.scene.add.rexInputText(Utils.xPercent(53), Utils.yPercent(70), 450, 35, {
			placeholder: 'Zadejte email',
			color: 'black',
			fontSize: '25px',
		});
		this.mailContainer.add(this.mailInput);
		this.dropDown1 = this.addDropdown(48, 64, [
			{ text: 'Nabídka detektivní spolupráce', value: 0 },
			{ text: 'DŮLEŽITÉ!!!!', value: 0 },
			{ text: 'Žádost o přístup k informacím', value: 1 },
			{ text: 'Chci znát pravdu', value: 0 },
		], 280);
		this.dropDown2 = this.addDropdown(40.5, 54.5, [
			{ text: 'filosofickém', value: 0 },
			{ text: 'zákona', value: 1 },
			{ text: 'směrnice', value: 0 },
			{ text: 'paragrafu', value: 0 },
		], 120);
		this.dropDown3 = this.addDropdown(52, 54.5, [
			{ text: '205/2303 Cz.', value: 0 },
			{ text: '42/1984 Sb.', value: 0 },
			{ text: '158/2001 Sb.', value: 0 },
			{ text: '106/1999 Sb.', value: 1 },
		], 120);
		this.dropDown4 = this.addDropdown(45.3, 51, [
			{ text: 'zákon o informačních systémech veřejné správy', value: 0 },
			{ text: 'právu nevypovídat', value: 0 },
			{ text: 'svobodném přístupu k informacím', value: 1 },
			{ text: 'ochraně utajovaných informací', value: 0 },
		], 440);
		this.dropDown5 = this.addDropdown(37, 44.3, [
			{ text: 'výběrového řízení', value: 1 },
			{ text: 'novinového článku', value: 0 },
			{ text: 'podezřelého vyhazovu', value: 0 },
			{ text: 'úředních drbů', value: 0 },
		], 190);
		this.dropDown6 = this.addDropdown(39, 41, [
			{ text: 'nového starosty města', value: 0 },
			{ text: 'asistentky ředitele', value: 0 },
			{ text: 'vrátného úřadu', value: 0 },
			{ text: 'ředitele odboru investic', value: 1 },
		], 250);
		this.dropDown7 = this.addDropdown(50.8, 37.7, [
			{ text: 'okamžité odpovědi', value: 0 },
			{ text: 'zašifrovaném telegramu', value: 0 },
			{ text: 'nejvyšším utajení', value: 0 },
			{ text: 'eletronické formě', value: 1 },
		], 220);
		this.dropDown8 = this.addDropdown(38.7, 28, [
			{ text: 'Stanislav Uhlíř', value: 1 },
			{ text: 'David Svoboda', value: 0 },
			{ text: 'Standa', value: 0 },
			{ text: 'Soukromé očko Stanley Coal', value: 0 },
		], 250);
		this.dropDown9 = this.addDropdown(40, 24.5, [
			{ text: '17. 11. 1989', value: 0 },
			{ text: 'Ano', value: 0 },
			{ text: '21. 7. 2022', value: 0 },
			{ text: '12. 5. 1950', value: 1 },
		], 130);
		this.dropDown10 = this.addDropdown(46.7, 21, [
			{ text: 'Obránců Míru 13, 690 06 Charvátská', value: 0 },
			{ text: 'Žemlová 44, 690 02 Břeclav', value: 1 },
			{ text: 'Ano', value: 0 },
			{ text: '516/12 Oakfield Avenue, Londýn', value: 0 },
		], 330);

		this.sendButton = this.uiHandler.createButton(
			Utils.xPercent(64),
			Utils.yPercent(21.5),
			UIImages.computerSendButton,
			() => this.trySend(),
		).setScale(1);
		this.mailContainer.add(this.sendButton);

		this.hide(false);
	}

	trySend() {
		if (
			this.mailInput.text === 'nemeckova@investice.cz'
			&& this.dropDown1.dataValue === 1
			&& this.dropDown2.dataValue === 1
			&& this.dropDown3.dataValue === 1
			&& this.dropDown4.dataValue === 1
			&& this.dropDown5.dataValue === 1
			&& this.dropDown6.dataValue === 1
			&& this.dropDown7.dataValue === 1
			&& this.dropDown8.dataValue === 1
			&& this.dropDown9.dataValue === 1
			&& this.dropDown10.dataValue === 1
		) {
			this.audioManager.playOneShot(SFX.uiCorrect, 0.7);
			this.scene.gameState.mailSent = true;
			this.hide();
			this.scene.singleDialogLine(Texts.computerCorrectMail, '26-computerCorrectMail', 0);
			this.scene.objectsManager.reRender();
		} else {
			this.audioManager.playOneShot(SFX.uiIncorrect);
			if (this.mailInput.text !== 'nemeckova@investice.cz') {
				this.mailInput.text = '';
			}

			// Advices
			if (this.mailInput.text === '') {
				this.scene.hintManager.tryHint(Hints.mailInvalid, 0, 'n20_mailInvalid');
			} else {
				this.invalidMailTries += 1;
				if (this.invalidMailTries >= 3) {
					this.scene.hintManager.tryHint(Hints.mail2, 0, 'n19_mail2');
				} else if (this.invalidMailTries >= 2) {
					this.scene.hintManager.tryHint(Hints.mail1, 0, 'n18_mail1');
				} else {
					this.scene.singleDialogLine(Texts.computerInvalidMail, '24-computerInvalidMail', 0);
				}
			}
		}
	}

	hide() {
		super.hide();
		this.loginScreen.setVisible(this.visible);
		this.loginButton.setVisible(this.visible);
		this.input.setVisible(this.visible);
		this.loginScreen.disableInteractive();
		this.loginButton.disableInteractive();
		this.mailContainer.setVisible(this.visible);
		this.mailContainer.disableInteractive();
	}

	show() {
		super.show();
		if (this.scene.gameState.businessCardRead === true && this.scene.gameState.newspaperRead === true) {
			this.mailContainer.setVisible(this.visible);
			this.mailContainer.setInteractive();
		} else {
			this.loginScreen.setVisible(this.visible);
			this.loginButton.setVisible(this.visible);
			this.input.setVisible(this.visible);
			this.loginScreen.setInteractive();
			this.loginButton.setInteractive();
		}
	}

	tryPassword() {
		if (this.input.text === 'davidsvoboda') {
			this.audioManager.playOneShot(SFX.uiCorrect, 0.7);
			this.scene.gameState.computerLoggedIn = true;
			this.hide();
			this.scene.singleDialogLine(Texts.computerCorrectPassword2, '13-computerCorrectPassword2', 0);
			this.scene.objectsManager.reRender();
		} else {
			// eslint-disable-next-line no-param-reassign
			this.input.text = '';
			this.audioManager.playOneShot(SFX.uiIncorrect);
		}
	}

	addDropdown(xPercent, yPercent, options, inputLength = 150) {
		const style = {
			label: {
				space: {
					left: 5, right: 5, top: 5, bottom: 5,
				},
				background: {
					color: 0xffffff,
					strokeColor: 0x000000,
					strokeWidth: 2,
				},
				text: {
					color: 'black',
					fixedWidth: inputLength,
					align: 'center',
				},
			},
			list: {
				space: {
					left: -10,
				},
			},
			button: {
				space: {
					left: 10, right: 10, top: 10, bottom: 10,
				},
				background: {
					color: 0xeaf2fa,
					strokeWidth: 0,
					'hover.strokeColor': 0x000000,
					'hover.strokeWidth': 2,
				},
				text: {
					fontSize: 20,
					color: 'black',
				},
			},
		};

		const dropdown = this.scene.rexUI.add.simpleDropDownList(style)
			.resetDisplayContent('--Vyber--')
			.setOptions(options)
			.setPosition(Utils.xPercent(xPercent), Utils.yPercent(yPercent))
			.layout();

		dropdown.on('button.click', (dropDownList, listPanel, button) => {
			dropDownList.setText(button.text);
			// eslint-disable-next-line no-param-reassign
			dropDownList.dataValue = button.value;
		});
		this.mailContainer.add(dropdown);

		return dropdown;
	}
}

export default ComputerOverlay;
