import { SFX, UIImages } from '../utils/Consts';
import Utils from '../utils/Utils';

class BaseOverlay {
	constructor(scene, uiHandler, audioManager, canRotate = false) {
		if (this.constructor === BaseOverlay) {
			throw new Error("Class is of abstract type and can't be instantiated");
		}

		this.scene = scene;
		this.uiHandler = uiHandler;
		this.audioManager = audioManager;
		this.canRotate = canRotate;
		this.isFront = true;

		this.assetContainer = this.scene.add.container(0, 0);
		this.assetContainer.setDepth(Number.MAX_SAFE_INTEGER - 1);

		this.backdrop = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.backdrop)
			.setScale(1).setAlpha(0.4);
		this.assetContainer.add(this.backdrop);

		this.closeButton = this.uiHandler.createButton(
			Utils.xPercent(95),
			Utils.yPercent(90),
			UIImages.closeButton,
			() => {
				this.scene.audioManager.playOneShot(SFX.uiClick, 0.3);				this.hide();
			},
			true,
		).setScale(0.5);
		this.assetContainer.add(this.closeButton);

		if (canRotate === true) {
			this.rotateButton = this.uiHandler.createButton(
				Utils.xPercent(95),
				Utils.yPercent(82),
				UIImages.rotateButton,
				() => this.rotate(),
				true,
			).setScale(0.5);
			this.assetContainer.add(this.rotateButton);
		}
	}

	// eslint-disable-next-line no-unused-vars
	hide(hiddenByPlayer = true) {
		this.scene.isInOverlay = false;
		this.visible = false;
		this.backdrop.setVisible(this.visible);
		this.backdrop.disableInteractive();
		this.closeButton.setVisible(this.visible);
		this.closeButton.disableInteractive();
		if (this.canRotate === true) {
			this.rotateButton.setVisible(this.visible);
			this.rotateButton.disableInteractive();
		}
	}

	show() {
		this.scene.isInOverlay = true;
		this.visible = true;
		this.backdrop.setVisible(this.visible);
		this.backdrop.setInteractive();
		this.closeButton.setVisible(this.visible);
		this.closeButton.setInteractive();
		if (this.canRotate === true) {
			this.rotateButton.setVisible(this.visible);
			this.rotateButton.setInteractive();
		}
	}

	rotate() {
		this.scene.audioManager.playOneShot(SFX.uiClick, 0.3);		this.isFront = !this.isFront;
	}
}

export default BaseOverlay;
