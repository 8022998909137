/* eslint-disable lines-between-class-members,no-unreachable */

import Config from '../utils/Config';

class GameState {
	// Common
	playerCode = 'ABCDE';
	playerName = null;
	// Tutorial
	bookFound = false;
	bookRead = false;
	bookClosed = false;
	familyTreeFound = false;
	familyTreeRead = false;
	familyTreeClosed = false;
	clockRunning = false;
	elapsedTime = 0;
	// Chapter 1
	photographFound = false;
	photographRead = false;
	idFound = false;
	idRead = false;
	letterFound = false;
	letterRead = false;
	letterOpened = false;
	blackmailAppeared = false;
	blackmailFound = false;
	blackmailRead = false;

	computerStarted = false;

	computerRead = false;

	computerLoggedIn = false;

	mainGoalShowed = false;
	translationFound = false;
	translationRead = false;
	mailFound = false;
	mailRead = false;
	bookPage2Found = false;

	// Chapter 2
	secondChapter = false;
	bookPage3Found = false;
	businessCardFound = false;
	businessCardRead = false;
	newspaperFound = false;
	newspaperRead = false;
	trapdoorClicked = false;
	diaryFound = false;
	diaryRead = false;
	diaryOpened = false;
	computerRead2 = false;
	mailSent = false;
	replyReceived = false;
	// Third chapter
	thirdChapter = false;
	bookPage4Found = false;
	replyPrinted = false;
	replyFound = false;
	replyRead = false;
	conversationFound = false;
	conversationRead = false;
	finished = false;
	// Family tree
	lucieFilled = false;
	davidFilled = false;
	ninaFilled = false;
	lindaFilled = false;
	hansFilled = false;
	brynolfFilled = false;
	marcelaFilled = false;
	marioFilled = false;

	constructor() {
		if (Config.useMockupSave === true) {
			// If some bool is set to true, then also ALL the previous should be true
			// ================== Tutorial ==================
			this.bookFound = true;
			this.bookRead = true;
			this.bookClosed = true;
			this.familyTreeRead = true;
			this.familyTreeClosed = true;
			this.familyTreeFound = true;
			this.clockRunning = true;
			this.elapsedTime = 90;

			// ================== First chapter ==================
			this.photographFound = true;
			this.photographRead = true;
			this.idFound = true;
			this.idRead = true;
			this.letterFound = true;
			this.letterRead = true;
			this.blackmailAppeared = true;
			this.computerStarted = true;
			this.computerRead = true;
			this.computerLoggedIn = true;
			this.blackmailFound = true;
			this.blackmailRead = true;
			this.mainGoalShowed = true;
			this.lucieFilled = true;
			this.davidFilled = true;
			this.ninaFilled = true;
			this.letterOpened = true;
			this.translationFound = true;
			this.translationRead = true;
			this.mailFound = true;
			this.mailRead = true;
			this.bookPage2Found = true;
			this.lindaFilled = true;
			this.hansFilled = true;
			this.brynolfFilled = true;

			// ================== Second chapter ==================
			this.secondChapter = true;
			this.bookPage3Found = true;
			this.businessCardFound = true;
			this.businessCardRead = true;
			this.newspaperFound = true;
			this.newspaperRead = true;
			this.mailSent = true;
			this.trapdoorClicked = true;
			this.diaryFound = true;
			this.diaryRead = true;
			this.marcelaFilled = true;

			// ================== Third chapter ==================
			this.thirdChapter = true;
			this.replyReceived = true;
			this.replyPrinted = true;
			this.replyFound = true;
			this.replyRead = true;
			this.conversationFound = true;
			this.conversationRead = true;
			this.bookPage4Found = true;
			this.marioFilled = true;
			this.diaryOpened = true;
		}
	}

	loadFromPlayer(player) {
		const data = JSON.parse(player.data);

		this.playerCode = data.playerCode;
		this.playerName = data.playerName;
		this.elapsedTime = data.elapsedTime;
		this.bookFound = data.bookFound;
		this.bookRead = data.bookRead;
		this.bookClosed = data.bookClosed;
		this.familyTreeFound = data.familyTreeFound;
		this.familyTreeRead = data.familyTreeRead;
		this.familyTreeClosed = data.familyTreeClosed;
		this.clockRunning = data.clockRunning;
		this.photographFound = data.photographFound;
		this.photographRead = data.photographRead;
		this.idFound = data.idFound;
		this.idRead = data.idRead;
		this.letterFound = data.letterFound;
		this.letterRead = data.letterRead;
		this.letterOpened = data.letterOpened;
		this.blackmailAppeared = data.blackmailAppeared;
		this.blackmailFound = data.blackmailFound;
		this.blackmailRead = data.blackmailRead;
		this.computerStarted = data.computerStarted;
		this.computerRead = data.computerRead;
		this.computerLoggedIn = data.computerLoggedIn;
		this.mainGoalShowed = data.mainGoalShowed;
		this.translationFound = data.translationFound;
		this.translationRead = data.translationRead;
		this.mailFound = data.mailFound;
		this.mailRead = data.mailRead;
		this.bookPage2Found = data.bookPage2Found;
		this.secondChapter = data.secondChapter;
		this.bookPage3Found = data.bookPage3Found;
		this.businessCardFound = data.businessCardFound;
		this.businessCardRead = data.businessCardRead;
		this.newspaperFound = data.newspaperFound;
		this.newspaperRead = data.newspaperRead;
		this.trapdoorClicked = data.trapdoorClicked;
		this.diaryFound = data.diaryFound;
		this.diaryRead = data.diaryRead;
		this.diaryOpened = data.diaryOpened;
		this.computerRead2 = data.computerRead2;
		this.mailSent = data.mailSent;
		this.replyReceived = data.replyReceived;
		this.thirdChapter = data.thirdChapter;
		this.bookPage4Found = data.bookPage4Found;
		this.replyPrinted = data.replyPrinted;
		this.replyFound = data.replyFound;
		this.replyRead = data.replyRead;
		this.conversationFound = data.conversationFound;
		this.conversationRead = data.conversationRead;
		this.finished = data.finished;
		this.lucieFilled = data.lucieFilled;
		this.davidFilled = data.davidFilled;
		this.ninaFilled = data.ninaFilled;
		this.lindaFilled = data.lindaFilled;
		this.hansFilled = data.hansFilled;
		this.brynolfFilled = data.brynolfFilled;
		this.marcelaFilled = data.marcelaFilled;
		this.marioFilled = data.marioFilled;
	}
}

export default GameState;
