/* eslint-disable lines-between-class-members,no-param-reassign */
import { Objects, SFX, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Texts from '../utils/Texts';
import Utils from '../utils/Utils';

class FamilyTreeOverlay extends BaseOverlay {
	isImportant = false;

	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager);
		this.familyTree = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.familyTreeBasic);
		this.assetContainer.add(this.familyTree);
		this.hide(false);

		this.lucieInput = this.createInput(27.7, 39.3, 'Lucie Svobodová', 'Lucie');
		this.lucieUnfilled = this.uiHandler.createImage(
			Utils.xPercent(28),
			Utils.yPercent(47.8),
			UIImages.familyTreeWomanUnfilled,
		);
		this.lucieFilled = this.uiHandler.createImage(
			Utils.xPercent(28),
			Utils.yPercent(47.8),
			UIImages.familyTreeLucieFilled,
		).setScale(0.5);

		this.davidInput = this.createInput(41.5, 39.3, 'David Svoboda', 'David');
		this.davidUnfilled = this.uiHandler.createImage(
			Utils.xPercent(41.3),
			Utils.yPercent(48.3),
			UIImages.familyTreeManUnfilled,
		);
		this.davidFilled = this.uiHandler.createImage(
			Utils.xPercent(41.5),
			Utils.yPercent(48),
			UIImages.familyTreeDavidFilled,
		).setScale(0.5);

		this.ninaInput = this.createInput(61.5, 41.5, 'Nina Bergman', 'Nina');
		this.ninaUnfilled = this.uiHandler.createImage(
			Utils.xPercent(61.8),
			Utils.yPercent(50.2),
			UIImages.familyTreeWomanUnfilled,
		);
		this.ninaFilled = this.uiHandler.createImage(
			Utils.xPercent(62),
			Utils.yPercent(50),
			UIImages.familyTreeNinaFilled,
		).setScale(0.5);

		this.lindaInput = this.createInput(50.1, 62.3, 'Linda Kaprová', 'Linda');
		this.lindaUnfilled = this.uiHandler.createImage(
			Utils.xPercent(50.2),
			Utils.yPercent(71.2),
			UIImages.familyTreeWomanUnfilled,
		);
		this.lindaFilled = this.uiHandler.createImage(
			Utils.xPercent(50.5),
			Utils.yPercent(72.5),
			UIImages.familyTreeLindaFilled,
		).setScale(0.5);

		this.hansInput = this.createInput(67.5, 24.7, 'Hans Bergman', 'Hans');
		this.hansUnfilled = this.uiHandler.createImage(
			Utils.xPercent(67.8),
			Utils.yPercent(32),
			UIImages.familyTreeChildUnfilled,
		);
		this.hansFilled = this.uiHandler.createImage(
			Utils.xPercent(67.8),
			Utils.yPercent(32.5),
			UIImages.familyTreeHansFilled,
		).setScale(0.5);

		this.brynolfInput = this.createInput(73.3, 41.8, 'Brynolf Bergman', 'Brynolf');
		this.brynolfUnfilled = this.uiHandler.createImage(
			Utils.xPercent(73),
			Utils.yPercent(50.7),
			UIImages.familyTreeManUnfilled,
		);
		this.brynolfFilled = this.uiHandler.createImage(
			Utils.xPercent(73.4),
			Utils.yPercent(50),
			UIImages.familyTreeBrynolfFilled,
		).setScale(0.5);

		this.marcelaInput = this.createInput(39, 16.5, 'Marcela Němečková', 'Marcela');
		this.marcelaUnfilled = this.uiHandler.createImage(
			Utils.xPercent(39.3),
			Utils.yPercent(25.3),
			UIImages.familyTreeWomanUnfilled,
		);
		this.marcelaFilled = this.uiHandler.createImage(
			Utils.xPercent(39.5),
			Utils.yPercent(25.7),
			UIImages.familyTreeMarcelaFilled,
		).setScale(0.5);

		this.marioInput = this.createInput(25, 16.5, 'Mário Vališ', 'Mario');
		this.marioUnfilled = this.uiHandler.createImage(
			Utils.xPercent(25),
			Utils.yPercent(25.5),
			UIImages.familyTreeManUnfilled,
		);
		this.marioFilled = this.uiHandler.createImage(
			Utils.xPercent(25),
			Utils.yPercent(25),
			UIImages.familyTreeMarioFilled,
		).setScale(0.5);

		this.hide(false);
	}

	renderInputs() {
		if (this.lucieInput) {
			const active = this.visible && this.scene.gameState.idRead === true;
			this.lucieInput.setVisible(active);
			this.lucieInput.backgroundGO.setVisible(active);
			this.lucieInput.submitGO.setVisible(active && this.scene.gameState.lucieFilled === false);
			this.lucieInput.successGO.setVisible(false);
			if (this.scene.gameState.lucieFilled === true) {
				FamilyTreeOverlay.disableInput(this.lucieInput);
			}
			this.lucieUnfilled.setVisible(this.visible && this.scene.gameState.lucieFilled === false);
			this.lucieFilled.setVisible(this.visible && this.scene.gameState.lucieFilled !== false);
		}

		if (this.davidInput) {
			const active = this.visible
				&& this.scene.gameState.computerLoggedIn === true
				&& this.scene.gameState.letterRead === true;
			this.davidInput.setVisible(active);
			this.davidInput.backgroundGO.setVisible(active);
			this.davidInput.submitGO.setVisible(active && this.scene.gameState.davidFilled === false);
			this.davidInput.successGO.setVisible(false);
			if (this.scene.gameState.davidFilled === true) {
				FamilyTreeOverlay.disableInput(this.davidInput);
			}
			this.davidUnfilled.setVisible(this.visible && this.scene.gameState.davidFilled === false);
			this.davidFilled.setVisible(this.visible && this.scene.gameState.davidFilled !== false);
		}

		if (this.ninaInput) {
			const active = this.visible
					&& this.scene.gameState.computerLoggedIn === true
					&& this.scene.gameState.letterRead === true;
			this.ninaInput.setVisible(active);
			this.ninaInput.backgroundGO.setVisible(active);
			this.ninaInput.submitGO.setVisible(active && this.scene.gameState.ninaFilled === false);
			this.ninaInput.successGO.setVisible(false);
			if (this.scene.gameState.ninaFilled === true) {
				FamilyTreeOverlay.disableInput(this.ninaInput);
			}
			this.ninaUnfilled.setVisible(this.visible && this.scene.gameState.ninaFilled === false);
			this.ninaFilled.setVisible(this.visible && this.scene.gameState.ninaFilled !== false);
		}

		if (this.lindaInput) {
			const active = this.visible && this.scene.gameState.mailRead === true;
			this.lindaInput.setVisible(active);
			this.lindaInput.backgroundGO.setVisible(active);
			this.lindaInput.submitGO.setVisible(active && this.scene.gameState.lindaFilled === false);
			this.lindaInput.successGO.setVisible(false);
			if (this.scene.gameState.lindaFilled === true) {
				FamilyTreeOverlay.disableInput(this.lindaInput);
			}
			this.lindaUnfilled.setVisible(this.visible && this.scene.gameState.lindaFilled === false);
			this.lindaFilled.setVisible(this.visible && this.scene.gameState.lindaFilled !== false);
		}

		if (this.hansInput) {
			const active = this.visible
				&& (this.scene.gameState.mailRead === true || this.scene.gameState.translationRead);
			this.hansInput.setVisible(active);
			this.hansInput.backgroundGO.setVisible(active);
			this.hansInput.submitGO.setVisible(active && this.scene.gameState.hansFilled === false);
			this.hansInput.successGO.setVisible(false);
			if (this.scene.gameState.hansFilled === true) {
				FamilyTreeOverlay.disableInput(this.hansInput);
			}
			this.hansUnfilled.setVisible(this.visible && this.scene.gameState.hansFilled === false);
			this.hansFilled.setVisible(this.visible && this.scene.gameState.hansFilled !== false);
		}

		if (this.brynolfInput) {
			const active = this.visible && this.scene.gameState.mailRead === true;
			this.brynolfInput.setVisible(active);
			this.brynolfInput.backgroundGO.setVisible(active);
			this.brynolfInput.submitGO.setVisible(active && this.scene.gameState.brynolfFilled === false);
			this.brynolfInput.successGO.setVisible(false);
			if (this.scene.gameState.brynolfFilled === true) {
				FamilyTreeOverlay.disableInput(this.brynolfInput);
			}
			this.brynolfUnfilled.setVisible(this.visible && this.scene.gameState.brynolfFilled === false);
			this.brynolfFilled.setVisible(this.visible && this.scene.gameState.brynolfFilled !== false);
		}

		if (this.marcelaInput) {
			const active = this.visible
				&& this.scene.gameState.newspaperRead === true
				&& this.scene.gameState.businessCardRead === true;
			this.marcelaInput.setVisible(active);
			this.marcelaInput.backgroundGO.setVisible(active);
			this.marcelaInput.submitGO.setVisible(active && this.scene.gameState.marcelaFilled === false);
			this.marcelaInput.successGO.setVisible(false);
			if (this.scene.gameState.marcelaFilled === true) {
				FamilyTreeOverlay.disableInput(this.marcelaInput);
			}
			this.marcelaUnfilled.setVisible(this.visible && this.scene.gameState.marcelaFilled === false);
			this.marcelaFilled.setVisible(this.visible && this.scene.gameState.marcelaFilled !== false);
		}

		if (this.marioInput) {
			const active = this.visible && this.scene.gameState.conversationRead === true;
			this.marioInput.setVisible(active);
			this.marioInput.backgroundGO.setVisible(active);
			this.marioInput.submitGO.setVisible(active && this.scene.gameState.marioFilled === false);
			this.marioInput.successGO.setVisible(false);
			if (this.scene.gameState.marioFilled === true) {
				FamilyTreeOverlay.disableInput(this.marioInput);
			}
			this.marioUnfilled.setVisible(this.visible && this.scene.gameState.marioFilled === false);
			this.marioFilled.setVisible(this.visible && this.scene.gameState.marioFilled !== false);
		}
	}

	hide(hiddenByPlayer = true) {
		super.hide();
		this.familyTree.setVisible(this.visible);
		this.familyTree.disableInteractive();
		this.renderInputs();

		// Closed for the first time, during Tutorial
		if (hiddenByPlayer && this.scene.gameState.familyTreeClosed === false) {
			this.scene.gameState.familyTreeFound = true;
			this.scene.gameState.familyTreeClosed = true;
			this.scene.singleDialogLine(Texts.tutorialFamilyTreeClosed, '06-tutorialFamilyTreeClosed', 0);
			this.scene.objectsManager.appearAnimation(Objects.familyTreeLocated);
		}
	}

	show() {
		super.show();
		this.familyTree.setVisible(this.visible);
		this.familyTree.setInteractive();
		this.renderInputs();
	}

	createInput(xPercent, yPercent, name, key) {
		const input = this.scene.add.rexInputText(Utils.xPercent(xPercent), Utils.yPercent(yPercent) + 1, 150, 40, {
			placeholder: 'Jméno Příjmení',
			color: '#086064d9',
			fontSize: '14.5px',
			align: 'center',
		}).setName(name);
		const onSubmit = (inputText) => {
			if (
				Utils.removeAccents(inputText.text.toLowerCase()) === Utils.removeAccents(inputText.name.toLowerCase())
			) {
				this.audioManager.playOneShot(SFX.uiCorrect, 0.7);
				switch (inputText.name) {
					case 'Lucie Svobodová':
						this.scene.gameState.lucieFilled = true;
						break;
					case 'David Svoboda':
						this.scene.gameState.davidFilled = true;
						break;
					case 'Nina Bergman':
						this.scene.gameState.ninaFilled = true;
						break;
					case 'Linda Kaprová':
						this.scene.gameState.lindaFilled = true;
						break;
					case 'Hans Bergman':
						this.scene.gameState.hansFilled = true;
						break;
					case 'Brynolf Bergman':
						this.scene.gameState.brynolfFilled = true;
						break;
					case 'Marcela Němečková':
						this.scene.gameState.marcelaFilled = true;
						break;
					case 'Mário Vališ':
						this.scene.gameState.marioFilled = true;
						break;
					default:
						break;
				}
				this.renderInputs();

				if (this.isImportant === true) {
					if (this.scene.gameState.marioFilled === true
						&& this.scene.gameState.marcelaFilled === true
					) {
						this.isImportant = false;
						this.scene.objectsManager.reRender();
					} else if (this.scene.gameState.lucieFilled === true
						&& this.scene.gameState.davidFilled === true
						&& this.scene.gameState.ninaFilled === true
						&& this.scene.canOpenLetter === false) {
						this.isImportant = false;
						this.scene.objectsManager.reRender();
					} else if (
						this.scene.gameState.lucieFilled === true
						&& this.scene.gameState.davidFilled === true
						&& this.scene.gameState.ninaFilled === true
						&& this.scene.gameState.lindaFilled === true
						&& this.scene.gameState.hansFilled === true
						&& this.scene.gameState.brynolfFilled === true) {
						this.isImportant = false;
						this.scene.objectsManager.reRender();
					}
				}
			} else {
				// eslint-disable-next-line no-param-reassign
				inputText.fontColor = 'red';
				this.audioManager.playOneShot(SFX.uiIncorrect);
				this.scene.delayedExecute(0.5, () => {
					inputText.fontColor = 'black';
					inputText.text = '';
				});
			}
		};

		input.backgroundGO =	this.uiHandler.createImage(
			Utils.xPercent(xPercent),
			Utils.yPercent(yPercent),
			UIImages.familyTreeInput,
		).setScale(0.22, 0.25);

		input.submitGO = this.uiHandler.createButton(
			Utils.xPercent(xPercent + 6),
			Utils.yPercent(yPercent),
			UIImages.familyTreeSubmit,
			() => onSubmit(input),
		).setScale(0.55);

		input.successGO = this.uiHandler.createImage(
			Utils.xPercent(xPercent),
			Utils.yPercent(yPercent),
			`familyTree${key}Text`,
		).setScale(0.5);

		input.on('keydown', (inputText, e) => {
			if ((e.key === 'Enter') && inputText.readOnly === false) {
				onSubmit(inputText);
			}
		});

		return input;
	}

	static disableInput(input) {
		input.text = input.name;
		input.readOnly = true;
		input.pointerEvents = 'none';
		input.successGO.setVisible(input.visible);
		input.setVisible(false);
	}
}

export default FamilyTreeOverlay;
