import Utils from '../utils/Utils';
import { Clock, Objects } from '../utils/Consts';

class UITimer {
	constructor(scene) {
		this.scene = scene;
		this.hiddenTimeStamp = 0;

		// Clock
		this.firstNumeral = this.scene.assetHandler.createImage(
			Utils.xPercent(71),
			Utils.yPercent(60.8),
			Clock.empty,
		).setOrigin()
			.setScale(0.28);
		this.secondNumeral = this.scene.assetHandler.createImage(
			Utils.xPercent(71.9),
			Utils.yPercent(60.8),
			Clock.empty,
		).setOrigin()
			.setScale(0.28);
		this.dots = this.scene.assetHandler.createImage(
			Utils.xPercent(72.6),
			Utils.yPercent(60.8),
			Clock.dots,
		).setOrigin()
			.setScale(0.28);
		this.thirdNumeral = this.scene.assetHandler.createImage(
			Utils.xPercent(73.3),
			Utils.yPercent(60.8),
			Clock.empty,
		).setOrigin()
			.setScale(0.28);
		this.fourthNumeral = this.scene.assetHandler.createImage(
			Utils.xPercent(74.2),
			Utils.yPercent(60.8),
			Clock.empty,
		).setOrigin()
			.setScale(0.28);

		this.updateTimer();
		this.scene.time.addEvent({
			delay: 1000,
			callback: () => this.updateTimer(),
			loop: true,
		});

		// To ensure that time is correctly counted when returning to non focused tab
		this.scene.game.events.on('hidden', () => {
			this.hiddenTimeStamp = performance.now();
		});
		this.scene.game.events.on('visible', () => {
			const elapsedTime = Math.floor((performance.now() - this.hiddenTimeStamp) / 1000);
			this.scene.gameState.elapsedTime += elapsedTime;
		});
	}

	setLightPipeline() {
		this.firstNumeral.setPipeline('Light2D');
		this.secondNumeral.setPipeline('Light2D');
		this.thirdNumeral.setPipeline('Light2D');
		this.fourthNumeral.setPipeline('Light2D');
		this.dots.setPipeline('Light2D');
	}

	resetPipeline() {
		this.firstNumeral.resetPipeline();
		this.secondNumeral.resetPipeline();
		this.thirdNumeral.resetPipeline();
		this.fourthNumeral.resetPipeline();
		this.dots.resetPipeline();
	}

	updateTimer() {
		this.scene.assetHandler.assetContainer.bringToTop(
			this.scene.GOContainer.fetchByName(Objects.clock, false, false),
		);
		this.scene.assetHandler.assetContainer.bringToTop(this.firstNumeral);
		this.scene.assetHandler.assetContainer.bringToTop(this.secondNumeral);
		this.scene.assetHandler.assetContainer.bringToTop(this.dots);
		this.scene.assetHandler.assetContainer.bringToTop(this.thirdNumeral);
		this.scene.assetHandler.assetContainer.bringToTop(this.fourthNumeral);

		if (this.scene.gameState.finished === true) {
			return;
		}
		if (this.scene.gameState.clockRunning === true) {
			this.scene.gameState.elapsedTime += 1;
			const timeString = Utils.elapsedTimeToReadableString(this.scene.gameState.elapsedTime);
			// TODO handle somehow timer overflow
			this.firstNumeral.setTexture(timeString.substring(0, 1));
			this.secondNumeral.setTexture(timeString.substring(1, 2));
			this.thirdNumeral.setTexture(timeString.substring(3, 4));
			this.fourthNumeral.setTexture(timeString.substring(4, 5));

			if (this.scene.gameState.elapsedTime % 15 === 0) {
				this.scene.animationManager.playSingleClockMovement();
			}
		} else {
			this.scene.gameState.elapsedTime = 0;
		}
	}
}

export default UITimer;
