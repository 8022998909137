// eslint-disable-next-line no-unused-vars
import { SFX, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Utils from '../utils/Utils';
// eslint-disable-next-line no-unused-vars
import Texts from '../utils/Texts';

class MainGoalOverlay extends BaseOverlay {
	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager);

		this.mainGoal = this.uiHandler.createImage(Utils.centerX(), Utils.yPercent(60), UIImages.mainGoalBasic)
			.setScale(0.4);
		this.assetContainer.add(this.mainGoal);
		this.hide(false);

		const onSubmit = () => {
			if (Utils.removeAccents(this.input.text.toLowerCase()) === 'lucie svobodova') {
				this.hide();
				this.audioManager.playOneShot(SFX.uiCorrect, 0.7);
				this.scene.finalCutscene();
			} else {
				this.audioManager.playOneShot(SFX.uiIncorrect);
				this.input.text = '';
				// this.scene.singleDialogLine(Texts.thirdMainGoalInvalid, null, 0); Removed, blocked input
			}
		};

		this.input = this.scene.add.rexInputText(Utils.xPercent(48), Utils.yPercent(20), 400, 100, {
			placeholder: 'Jméno Příjmení',
			borderColor: 'black',
			color: 'black',
			fontSize: '45px',
		});

		this.input.on('keydown', (inputText, e) => {
			if ((e.key === 'Enter')) {
				onSubmit();
			}
		});
		this.assetContainer.add(this.input);

		this.background = this.uiHandler.createImage(
			Utils.xPercent(48),
			Utils.yPercent(20),
			UIImages.input,
		);
		this.assetContainer.add(this.background);

		this.submit = this.uiHandler.createButton(
			Utils.xPercent(67),
			Utils.yPercent(20),
			UIImages.submit,
			() => {
				onSubmit();
			},
		).setScale(1);
		this.assetContainer.add(this.submit);
		this.hide();
	}

	hide() {
		super.hide();
		this.assetContainer.setVisible(this.visible);
	}

	show() {
		super.show();
		this.assetContainer.setVisible(this.visible);
		this.input.setVisible(this.scene.finalQuestion === true);
		this.background.setVisible(this.scene.finalQuestion === true);
		this.submit.setVisible(this.scene.finalQuestion === true);
	}
}

export default MainGoalOverlay;
