import { Objects, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Utils from '../utils/Utils';

class IdOverlay extends BaseOverlay {
	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager);
		this.id = this.uiHandler.createImage(Utils.centerX(), Utils.yPercent(70), UIImages.idBasic);
		this.assetContainer.add(this.id);
		this.hide(false);
	}

	hide(hiddenByPlayer = true) {
		super.hide(hiddenByPlayer);
		this.id.setVisible(this.visible);

		if (hiddenByPlayer && this.scene.gameState.idFound === false) {
			this.scene.gameState.idFound = true;
			this.scene.objectsManager.appearAnimation(Objects.idLocated);
		}
	}

	show() {
		super.show();
		this.id.setVisible(this.visible);
	}
}

export default IdOverlay;
