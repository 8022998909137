/* eslint-disable lines-between-class-members */

class Config {
	static debug = false;
	static fastDialogues = false;
	static useMockupSave = false;

	static windowWidth = 1500;
	static windowHeight = 900;

	static COLOR_PRIMARY = 0x4e342e;
	static COLOR_LIGHT = 0x7b5e57;
}

export default Config;
