/* eslint-disable no-param-reassign */

class PlayerControls {
	static input = true;

	static cursors = {
		default: 'cursor.png',
		wait: 'cursorWaiting.png',
	};

	static setDefaultCursor(scene) {
		this.setCursor(scene, 'default');
	}

	static setCursor(scene, cursor) {
		const cursorURL = this.cursors[cursor];
		if (cursorURL) {
			scene.input.setDefaultCursor(`url(${process.env.PUBLIC_URL}/assets/images/ui/${cursorURL}) 20 20, pointer`);
		}
	}

	static disableControl(scene) {
		this.input = false;
		this.setCursor(scene, 'wait');
	}

	static enableControl(scene) {
		this.input = true;
		this.setCursor(scene, 'default');
	}
}

export default PlayerControls;
