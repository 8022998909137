/* global window */
import { Objects, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Utils from '../utils/Utils';

class MailOverlay extends BaseOverlay {
	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager);
		this.mail = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.mailBasic);
		this.assetContainer.add(this.mail);

		this.linkButton = this.uiHandler.createButton(
			Utils.xPercent(76),
			Utils.yPercent(43.5),
			UIImages.linkButton,
			() => window.open('https://1url.cz/@kaprova', '_blank'),
		).setScale(0.4);
		this.assetContainer.add(this.linkButton);

		this.hide(false);
	}

	hide(hiddenByPlayer = true) {
		super.hide();
		this.mail.setVisible(this.visible);
		this.mail.disableInteractive();
		this.linkButton.setVisible(this.visible);
		this.linkButton.disableInteractive();

		if (hiddenByPlayer && this.scene.gameState.mailFound === false) {
			this.scene.gameState.mailFound = true;
			this.scene.objectsManager.appearAnimation(Objects.mailLocated);
		}

		if (hiddenByPlayer && this.scene.canBeShownSecondFamilyTreeBlock === false) {
			this.scene.delayedExecute(30, () => {
				this.scene.canBeShownSecondFamilyTreeBlock = true;
			});
		}
	}

	show() {
		super.show();
		this.mail.setVisible(this.visible);
		this.mail.setInteractive();
		this.linkButton.setVisible(this.visible);
		this.linkButton.setInteractive();
	}
}

export default MailOverlay;
