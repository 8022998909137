/* global document, navigator */

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

if (tooltipTriggerList) {
	tooltipTriggerList.forEach((element) => {
		// eslint-disable-next-line no-new
		new bootstrap.Tooltip(element);
	});
}

const copy = document.querySelectorAll('.js-copy');

if (copy) {
	copy.forEach((element) => {
		element.addEventListener('click', () => {
			const textToCopy = element.dataset.link;
			navigator.clipboard.writeText(textToCopy);
			bootstrap.Tooltip.getOrCreateInstance(element).show();
			setTimeout(() => {
				bootstrap.Tooltip.getOrCreateInstance(element).hide();
			}, 1000);
		});
	});
}
