/* eslint-disable lines-between-class-members,max-classes-per-file */

export class Scenes {
	static introScene = 'IntroScene';
	static gameScene = 'GameScene';
	static outroScene = 'OutroScene';
}

export class SFX {
	static fanfare = 'fanfare';
	static objectFound = 'objectFound';
	static pageTurn = 'pageTurn';
	static uiClick = 'uiClick';
	static uiCorrect = 'uiCorrect';
	static uiIncorrect = 'uiIncorrect';
}

export class Clock {
	static dots = 'dots';
	static empty = 'empty';
	static one = '1';
	static two = '2';
	static three = '3';
	static four = '4';
	static five = '5';
	static six = '6';
	static seven = '7';
	static eight = '8';
	static nine = '9';
}

export class Music {
	static intro = 'intro';
	static outro = 'outro';
	static game1 = 'game1';
	static game2 = 'game2';
	static game3 = 'game3';
	static game4 = 'game4';
	static game5 = 'game5';
	static game6 = 'game6';
	static game7 = 'game7';
}

export class Objects {
	static background = 'background';
	static backgroundNight = 'backgroundNight';
	static hat = 'hat';
	static book = 'book';
	static bookLocated = 'bookLocated';
	static bookPage2 = 'bookPage2';
	static bookPage3 = 'bookPage3';
	static bookPage4 = 'bookPage4';
	static familyTree = 'familyTree';
	static familyTreeLocated = 'familyTreeLocated';
	static familyTreeImportant = 'familyTreeImportant';
	static clock = 'clock';
	static photograph = 'photograph';
	static photographLocated = 'photographLocated';
	static id = 'id';
	static idLocated = 'idLocated';
	static lamp = 'lamp';
	static letter = 'letter';
	static letterLocated = 'letterLocated';
	static blackmail = 'blackmail';
	static blackmailLocated = 'blackmailLocated';
	static computer = 'computer';
	static computerLocated = 'computerLocated';
	static computerLocatedUpdating = 'computerLocatedUpdating';
	static computerLocatedDesktop = 'computerLocatedDesktop';
	static computerLocatedMail = 'computerLocatedMail';
	static computerLocatedSendMail = 'computerLocatedSendMail';
	static computerLocatedEnd = 'computerLocatedEnd';
	static computerLocatedStarting = 'computerLocatedStarting';
	static plug = 'plug';
	static plugLocated = 'plugLocated';
	static mainGoal = 'mainGoal';
	static mainGoalImportant = 'mainGoalImportant';
	static translation = 'translation';
	static translationLocated = 'translationLocated';
	static mail = 'mail';
	static mailLocated = 'mailLocated';
	static businessCard = 'businessCard';
	static businessCardLocated = 'businessCardLocated';
	static newspaper = 'newspaper';
	static newspaperLocated = 'newspaperLocated';
	static trapdoor = 'trapdoor';
	static diary = 'diary';
	static diaryLocated = 'diaryLocated';
	static reply = 'reply';
	static replyLocated = 'replyLocated';
	static conversation = 'conversation';
	static conversationLocated = 'conversationLocated';
	static window = 'window';
}

export class UIImages {
	static input = 'uiInput';
	static submit = 'uiSubmit';
	static backdrop = 'backdrop';
	static dropdownBackground = 'dropdownBackground';
	static dialogBackground = 'dialogBackground';
	static closeButton = 'closeButton';
	static rotateButton = 'rotateButton';
	static openButton = 'openButton';
	static unlockButton = 'unlockButton';
	static keyButton = 'keyButton';
	static nextButton = 'nextButton';
	static linkButton = 'linkButton';

	static bookCover = 'bookCover';
	static bookPage1 = 'bookPage1';
	static bookPage2 = 'bookPage2Detail';
	static bookPage3 = 'bookPage3Detail';
	static bookPage4 = 'bookPage4Detail';
	static bookMark1 = 'bookMark1';
	static bookMark2 = 'bookMark2';
	static bookMark3 = 'bookMark3';
	static bookMark4 = 'bookMark4';
	static link = 'link';

	static familyTreeBasic = 'familyTreeBasic';
	static familyTreeManUnfilled = 'familyTreeManUnfilled';
	static familyTreeWomanUnfilled = 'familyTreeWomanUnfilled';
	static familyTreeChildUnfilled = 'familyTreeChildUnfilled';
	static familyTreeLucieFilled = 'familyTreeLucieFilled';
	static familyTreeDavidFilled = 'familyTreeDavidFilled';
	static familyTreeNinaFilled = 'familyTreeNinaFilled';
	static familyTreeBrynolfFilled = 'familyTreeBrynolfFilled';
	static familyTreeHansFilled = 'familyTreeHansFilled';
	static familyTreeLindaFilled = 'familyTreeLindaFilled';
	static familyTreeMarcelaFilled = 'familyTreeMarcelaFilled';
	static familyTreeMarioFilled = 'familyTreeMarioFilled';
	static familyTreeLucieText = 'familyTreeLucieText';
	static familyTreeDavidText = 'familyTreeDavidText';
	static familyTreeNinaText = 'familyTreeNinaText';
	static familyTreeBrynolfText = 'familyTreeBrynolfText';
	static familyTreeHansText = 'familyTreeHansText';
	static familyTreeLindaText = 'familyTreeLindaText';
	static familyTreeMarcelaText = 'familyTreeMarcelaText';
	static familyTreeMarioText = 'familyTreeMarioText';
	static familyTreeInput = 'familyTreeInput';
	static familyTreeSubmit = 'familyTreeSubmit';

	static photographBasic = 'photographBasic';
	static photographReverse = 'photographReverse';

	static idBasic = 'idBasic';

	static letterBasic = 'letterBasic';
	static letterReverse = 'letterReverse';
	static letterReverseOpened = 'letterReverseOpened';

	static blackmailBasic = 'blackmailBasic';

	static computerBasic = 'computerBasic';
	static computerBasicButton = 'computerBasicButton';
	static computerMail = 'computerMail';
	static computerSendButton = 'sendButton';

	static mailBasic = 'mailBasic';

	static translationBasic = 'translationBasic';

	static businessCardBasic = 'businessCardBasic';

	static newspaperBasic = 'newspaperBasic';

	static diaryBasic = 'diaryBasic';
	static diaryReverse = 'diaryReverse';
	static diaryOpen = 'diaryOpen';
	static lock = 'lock';
	static lock0 = 'lock0';
	static lock1 = 'lock1';
	static lock2 = 'lock2';
	static lock3 = 'lock3';
	static lock4 = 'lock4';
	static lock5 = 'lock5';
	static lock6 = 'lock6';
	static lock7 = 'lock7';
	static lock8 = 'lock8';
	static lock9 = 'lock9';
	static changeNumberButtonUp = 'changeNumberButtonUp';
	static changeNumberButtonDown = 'changeNumberButtonDown';

	static replyBasic = 'replyBasic';
	static conversationBasic = 'conversationBasic';
	static mainGoalBasic = 'mainGoalBasic';
}

export class State {
	static UNUSED = 'unused';
	static PLAYING = 'playing';
	static FINISHED = 'finished';
}
