import { Objects, SFX } from '../utils/Consts';
import Utils from '../utils/Utils';

class ObjectsManager {
	constructor(scene) {
		this.scene = scene;
	}

	appearAnimation(objectName) {
		this.reRender();
		const object = this.scene.GOContainer.fetchByName(objectName);
		object.preFX.setPadding(32);
		const fx = object.preFX.addReveal(0.01, 0, 0);
		let glow = null;
		this.scene.tweens.add({
			targets: fx,
			progress: 1,
			repeat: 0,
			duration: 500,
			onComplete: () => {
				this.scene.audioManager.playOneShot(SFX.objectFound, 0.5);
				glow = object.preFX.addGlow(0xffffff, 0, 0, false); // Distance and quality set in Phaser Config
				this.scene.tweens.add({
					targets: glow,
					outerStrength: 5,
					yoyo: true,
					repeat: 0,
					ease: 'sine.inout',
				});
			},
		});

		// Dispose
		this.scene.delayedExecute(2.1, () => {
			if (this.scene.gameState.secondChapter === true && this.scene.gameState.thirdChapter !== true) {
				object.setPipeline('Light2D');
			}
			fx.destroy();
			glow.destroy();
		});
	}

	reRender() {
		// Tutorial book
		if (this.scene.gameState.bookFound === false) {
			this.updateOrCreate(Objects.book, 83.4, 35.5, 0.52, true);
		} else if (this.scene.gameState.bookFound === true) {
			this.updateOrCreate(Objects.bookLocated, 25.3, 42, 0.52, true);
		}

		// Family tree
		if (this.scene.gameState.familyTreeFound === false) {
			this.updateOrCreate(Objects.familyTree, 47.4, 30, 0.53, this.scene.gameState.bookClosed);
		} else {
			this.updateOrCreate(Objects.familyTreeLocated, 30.5, 63, 0.50, true);
			const important = this.updateOrCreate(Objects.familyTreeImportant, 30.5, 63, 0.50);
			important.setVisible(this.scene.familyTreeManager.isImportant);
		}

		// Clock
		this.updateOrCreate(
			Objects.clock,
			72.6,
			58.5,
			1,
			this.scene.gameState.familyTreeClosed === true && this.scene.gameState.clockRunning === false,
		);

		// Lamp
		this.updateOrCreate(
			Objects.lamp,
			49,
			80,
			0.5,
		);

		// Photograph
		if (this.scene.gameState.photographFound === false) {
			this.updateOrCreate(Objects.photograph, 60.6, 28.2, 1, this.scene.firstChapterStarted);
		} else {
			this.updateOrCreate(Objects.photographLocated, 60, 57, 1, true);
		}

		// Id
		if (this.scene.gameState.idFound === false) {
			this.updateOrCreate(Objects.id, 88.3, 46, 1, this.scene.firstChapterStarted);
		} else {
			this.updateOrCreate(Objects.idLocated, 63.7, 58, 1, true);
		}

		// Letter
		if (this.scene.gameState.letterFound === false) {
			this.updateOrCreate(Objects.letter, 12.8, 27.7, 1, this.scene.firstChapterStarted);
		} else {
			this.updateOrCreate(Objects.letterLocated, 60.5, 62.3, 1, true);
		}

		// Blackmail
		if (this.scene.gameState.blackmailAppeared === true && this.scene.gameState.blackmailFound === false) {
			this.updateOrCreate(Objects.blackmail, 70, 11, 1, this.scene.gameState.blackmailAppeared);
		} else if (this.scene.gameState.blackmailFound === true) {
			this.updateOrCreate(Objects.blackmailLocated, 48, 51.5, 0.5, true);
		}

		// Plug
		if (this.scene.gameState.computerStarted === false) {
			this.updateOrCreate(Objects.plug, 39.3, 32, 1, this.scene.firstChapterStarted, false);
		} else {
			this.updateOrCreate(Objects.plugLocated, 39.1, 33, 1);
		}

		// Computer
		this.updateOrCreate(Objects.computer, 33.5, 46.3, 0.55);
		if (this.scene.gameState.computerStarted === true) {
			let texture = Objects.computerLocated;

			if (this.scene.gameState.computerLoading === true) {
				texture = Objects.computerLocatedStarting;
			}

			if (this.scene.gameState.computerLoading === false) {
				texture = Objects.computerLocated;
			}

			if (this.scene.gameState.replyPrinted === true) {
				texture = Objects.computerLocatedEnd;
			} else if (this.scene.gameState.replyReceived === true && this.scene.gameState.replyPrinted === false) {
				texture = Objects.computerLocatedMail;
			} else if (this.scene.gameState.mailSent === false
				&& this.scene.gameState.businessCardRead === true
				&& this.scene.gameState.newspaperRead === true
			) {
				texture = Objects.computerLocatedSendMail;
			} else if (this.scene.gameState.mailSent === true && this.scene.gameState.replyReceived === false) {
				texture = Objects.computerLocatedDesktop;
			} else if (this.scene.gameState.computerLoggedIn === true) {
				texture = Objects.computerLocatedUpdating;
			}
			this.updateOrCreate(Objects.computerLocated, 33.3, 49, 0.55, true)
				.setTexture(texture);
		}

		// Translation
		if (this.scene.gameState.letterOpened === true && this.scene.gameState.translationFound === false) {
			this.updateOrCreate(Objects.translation, 53.5, 12, 0.5, true);
		} else if (this.scene.gameState.translationFound === true) {
			this.updateOrCreate(Objects.translationLocated, 62.5, 49.8, 0.5, true);
		}

		// Page 2
		if (this.scene.gameState.letterOpened === true
			&& this.scene.gameState.bookPage2Found === false) {
			this.updateOrCreate(Objects.bookPage2, 70.4, 31.7, 0.5, true);
		}

		// Mail
		if (this.scene.gameState.letterOpened === true && this.scene.gameState.mailFound === false) {
			this.updateOrCreate(Objects.mail, 48.5, 15.2, 0.5, true);
		} else if (this.scene.gameState.mailFound === true) {
			this.updateOrCreate(Objects.mailLocated, 44, 62, 0.5, true);
		}

		// Business Card
		if (this.scene.gameState.secondChapter === true && this.scene.gameState.businessCardFound === false) {
			this.updateOrCreate(Objects.businessCard, 13.9, 9.3, 0.5, true, false);
		} else if (this.scene.gameState.businessCardFound === true) {
			this.updateOrCreate(Objects.businessCardLocated, 61.5, 52.3, 0.5, true);
		}

		// Newspaper
		if (this.scene.gameState.secondChapter === true && this.scene.gameState.newspaperFound === false) {
			this.updateOrCreate(Objects.newspaper, 93.7, 23.5, 0.5, true);
		} else if (this.scene.gameState.newspaperFound === true) {
			this.updateOrCreate(Objects.newspaperLocated, 44, 50.8, 0.5, true);
		}

		// Page 3
		if (this.scene.gameState.secondChapter === true
			&& this.scene.gameState.bookPage3Found === false) {
			this.updateOrCreate(Objects.bookPage3, 67, 4.7, 0.5, true);
		}

		// Trapdoor
		if (this.scene.gameState.secondChapter === true && this.scene.gameState.trapdoorClicked === false) {
			this.updateOrCreate(Objects.trapdoor, 25.6, 76.6, 1, true);
		}

		// Diary
		if (this.scene.gameState.trapdoorClicked === true && this.scene.gameState.diaryFound === false) {
			this.updateOrCreate(Objects.diary, 23.8, 14.3, 0.5, true);
		} else if (this.scene.gameState.diaryFound === true) {
			this.updateOrCreate(Objects.diaryLocated, 48.3, 60.2, 0.5, true);
		}

		// Page 4
		if (this.scene.gameState.thirdChapter === true
			&& this.scene.gameState.bookPage4Found === false) {
			this.updateOrCreate(Objects.bookPage4, 76.5, 60, 0.5, true);
		}

		// Reply
		if (this.scene.gameState.replyPrinted === true && this.scene.gameState.replyFound === false) {
			this.updateOrCreate(Objects.reply, 30.95, 38.9, 1, true);
		} else if (this.scene.gameState.replyFound === true) {
			this.updateOrCreate(Objects.replyLocated, 59, 50.5, 0.5, true);
		}

		// Conversation
		if (this.scene.gameState.replyPrinted === true && this.scene.gameState.conversationFound === false) {
			this.updateOrCreate(Objects.conversation, 31, 23.7, 1.1, true);
		} else if (this.scene.gameState.conversationFound === true) {
			this.updateOrCreate(Objects.conversationLocated, 44, 56.8, 0.5, true);
		}

		// Main goal
		if (this.scene.gameState.mainGoalShowed === true) {
			this.updateOrCreate(Objects.mainGoal, 53.5, 57, 0.5, true);
			const important = this.updateOrCreate(Objects.mainGoalImportant, 53.5, 57, 0.5);
			important.setVisible(this.scene.finalQuestion);
		}

		// Window
		this.updateOrCreate(Objects.window, 10.87, 50.3, 0.505, false);
	}

	updateOrCreate(name, xPercent, yPercent, scale, interactive = false, pixelPerfect = true) {
		let go = this.scene.GOContainer.fetchByName(name);
		if (!go) {
			go = this.create(name, xPercent, yPercent, scale, interactive);
			if (this.scene.gameState.secondChapter === true && this.scene.gameState.thirdChapter === false) {
				go.setPipeline('Light2D');
			}
		}

		// Always update interactivity
		if (interactive === false) {
			go.disableInteractive();
		} else if (interactive === true && pixelPerfect) {
			go.setInteractive(this.scene.input.makePixelPerfect());
		} else if (interactive === true) {
			go.setInteractive();
		}

		return go;
	}

	// HELPER METHODS
	create(name, xPercent, yPercent, scale, interactive = false) {
		const go = this.scene.assetHandler.createImage(
			Utils.xPercent(xPercent),
			Utils.yPercent(yPercent),
			name,
		).setScale(scale)
			.setName(name)
			.setOrigin();

		if (interactive === true) {
			go.setInteractive(this.scene.input.makePixelPerfect());
		}

		this.scene.GOContainer.add(go);
		return go;
	}
}

export default ObjectsManager;
