import Utils from '../utils/Utils';

class CameraManager {
	static zoomToObject(object, camera, transition = 1000, zoom = 1.2) {
		camera.pan(object.x, object.y, transition);
		camera.zoomTo(zoom, transition);
	}

	static zoomReset(camera, transition = 1000) {
		camera.pan(Utils.centerX(), Utils.centerY(), transition);
		camera.zoomTo(1, transition);
	}
}

export default CameraManager;
