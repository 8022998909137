import BaseScene from './BaseScene';
import {
	Music, Scenes, SFX, UIImages, State,
} from '../utils/Consts';
import Utils from '../utils/Utils';
import GameState from '../gameSceneHelpers/GameState';
import BackendBridge from '../utils/BackendBridge';

class OutroScene extends BaseScene {
	constructor() {
		super({ key: Scenes.outroScene });
	}

	onPlayerLoaded() {
		if (this.player.state === State.FINISHED) {
			// Skip name input screen, if player has already submitted score
			// TODO solve delay problem, needs to be instant
			this.showHighScore(this.container);
		} else {
			this.showNameInput();
		}
	}

	preload() {
		this.commonPreload();
	}

	create(data) {
		this.commonCreate();
		this.gameState = data.gameState ?? new GameState();
		this.audioManager.playOneShot(Music.outro, 0.5);

		const COLOR_PRIMARY = 0x9f164e;
		const COLOR_LIGHT = 0xc42c6a;
		const COLOR_DARK = 0x851040;

		this.container = this.add.container();
		this.container.setSize(400, 1000);

		this.scorePanel = this.rexUI.add.scrollablePanel({
			x: Utils.xPercent(32),
			y: Utils.yPercent(51),
			width: 650,
			height: 380,
			scrollMode: 'y',
			background: this.rexUI.add.roundRectangle({
				strokeColor: COLOR_LIGHT,
				radius: 10,
			}),
			panel: {
				child: this.container,
				mask: { padding: 1 },
			},
			slider: {
				track: this.rexUI.add.roundRectangle({
					width: 20,
					radius: 10,
					color: COLOR_DARK,
				}),
				thumb: this.rexUI.add.roundRectangle({
					radius: 13,
					color: COLOR_PRIMARY,
				}),
			},
			mouseWheelScroller: {
				focus: false,
				speed: 0.1,
			},
			header: this.rexUI.add.label({
				space: {
					left: 5,
					right: 5,
					top: 5,
					bottom: 5,
				},
				background: this.rexUI.add.roundRectangle({ color: COLOR_PRIMARY }),
			}),
			footer: this.rexUI.add.label({
				space: {
					left: 5,
					right: 5,
					top: 5,
					bottom: 5,
				},
				background: this.rexUI.add.roundRectangle({ color: COLOR_PRIMARY }),
			}),
			space: {
				left: 20,
				right: 20,
				top: 20,
				bottom: 20,
				panel: 3,
				header: 5,
				footer: 5,
			},
		})
			.setVisible(false)
			.layout();

		this.assetHandler.createImage(Utils.xPercent(50), Utils.centerY(), 'outroBackground')
			.setScale(1.05);
		this.textImage = this.assetHandler.createImage(Utils.xPercent(32), Utils.centerY(), 'outroText')
			.setScale(1)
			.setVisible(false);
		this.textBox = this.rexUI.add.textBox({
			x: Utils.xPercent(32),
			y: Utils.centerY(),
			width: 600,
			height: 550,
			text: this.rexUI.add.BBCodeText(0, 0, 'Záhada rozluštěna!\nStanda se teď musí rozhodnout, jak s nově nalezenými informacemi naloží. A ty už teď víš, jak pracovat s katastrem, hlídačem státu a databází znalců. Dokonce umíš napsat i stošestku!'
				+ `\n\nCelé se ti to podařilo za [b]${Utils.elapsedTimeToReadableString(this.gameState.elapsedTime)}[/b].\nJak se chceš jmenovat v žebříčku?`, {
				fixedWidth: 600,
				fixedHeight: 550,
				fontSize: '35px',
				fontFamily: 'Merriweather',
				wrap: {
					mode: 'word',
					width: 600,
				},
				maxLines: 40,
			})
				.setDepth(9999),
			align: {
				text: 'left',
			},
			space: {
				left: 20,
				right: 20,
				top: 40,
				bottom: 20,
				text: 10,
			},
		})
			.setOrigin(0.5, 0.5)
			.setDepth(9998)
			.layout()
			.setVisible(false);

		this.textImage2 = this.assetHandler.createImage(Utils.xPercent(32), Utils.centerY(), 'outroText2')
			.setScale(1)
			.setVisible(false);
		this.textBox2 = this.rexUI.add.textBox({
			x: Utils.xPercent(32),
			y: Utils.yPercent(47),
			width: 600,
			height: 550,
			text: this.rexUI.add.BBCodeText(0, 0, 'Tady je vidět, jak si vedli ostatní:'
				+ '\n\n\n\n\n\n\n\n\nA nezapomeň odteď dál být aktivním občanem!', {
				fixedWidth: 600,
				fixedHeight: 550,
				fontSize: '37px',
				fontFamily: 'Merriweather',
				wrap: {
					mode: 'word',
					width: 600,
				},
				maxLines: 40,
			})
				.setDepth(9999),
			align: {
				text: 'left',
			},
			space: {
				left: 20,
				right: 20,
				top: 40,
				bottom: 20,
				text: 10,
			},
		})
			.setOrigin(0.5, 0.5)
			.setDepth(9998)
			.layout()
			.setVisible(false);

		this.input = this.add.rexInputText(Utils.xPercent(20), Utils.yPercent(26.5), 250, 50, {
			placeholder: 'Jméno',
			color: 'black',
			fontSize: '35px',
			fontFamily: 'Merriweather',
		}).setVisible(false);

		this.button = this.assetHandler.createImage(Utils.xPercent(32.3), Utils.yPercent(26.8), UIImages.submit)
			.setInteractive()
			.on('pointerdown', () => {
				if (this.input.text !== '') {
					this.audioManager.playOneShot(SFX.uiClick, 0.3);
					this.button.setVisible(false);
					this.textImage.setVisible(false);
					this.textBox.setVisible(false);
					this.input.setVisible(false);
					this.textImage2.setVisible(true);
					this.textBox2.setVisible(true);
					this.scorePanel.setVisible(true);
					this.gameState.playerName = this.input.text;
					BackendBridge.updateGameStateByPlayerCode(this.playerCode, this.gameState, () => {
						this.showHighScore(this.container);
					});
				} else {
					this.audioManager.playOneShot(SFX.uiIncorrect);
				}
			})
			.setScale(0.7)
			.setVisible(false);
	}

	showHighScore(container) {
		this.textImage2.setVisible(true);
		this.textBox2.setVisible(true);
		this.scorePanel.setVisible(true);

		// TODO probably prefetch data somehow and just include the newly added score,
		// so we do not have to wait for BE response
		BackendBridge.getHighScoreByCode(this.playerCode).then((data) => {
			data.forEach((row, index) => {
				const text = this.rexUI.add.BBCodeText(0, index * 80, `\n${index + 1}. ${row.playerName}`, {
					fontSize: '30px',
					fontFamily: 'Merriweather',
					backgroundColor: ((index + 1) / 2) === 1 ? '#B01856' : '#9F164E',
					padding: {
						top: -15,
						bottom: 30,
					},
					fixedWidth: 600,
				});

				const time = this.rexUI.add.BBCodeText(500, index * 80, `${Utils.elapsedTimeToReadableString(row.finalElapsedTime)}`, {
					fontSize: '30px',
					fontFamily: 'Merriweather',
					padding: {
						top: 17,
						bottom: 30,
					},
					fixedWidth: 100,
				});

				container.add(text);
				container.add(time);
			});
		});
	}

	showNameInput() {
		this.button.setVisible(true);
		this.textImage.setVisible(true);
		this.textBox.setVisible(true);
		this.input.setVisible(true);
	}
}

export default OutroScene;
