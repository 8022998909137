class GOContainer {
	gameObjects = [];

	constructor(scene) {
		this.scene = scene;
	}

	add(gameObject) {
		this.gameObjects.push(gameObject);
	}

	allowLights() {
		this.gameObjects.forEach((object) => object.setPipeline('Light2D'));
	}

	disableLights() {
		this.gameObjects.forEach((object) => object.resetPipeline());
	}

	includes(gameObject) {
		return this.gameObjects.includes(gameObject);
	}

	includesByName(name) {
		return this.gameObjects.find((go) => go.name === name) === undefined;
	}

	fetchByName(name, shouldRemove = false, shouldDestroy = false) {
		const res = this.gameObjects.find((go) => go.name === name);
		if (shouldRemove === true && res) {
			this.gameObjects = this.gameObjects.filter((go) => go !== res);
		}
		if (shouldDestroy === true && res) {
			res.destroy();
		}
		return res;
	}
}

export default GOContainer;
