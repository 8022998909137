/* eslint-disable lines-between-class-members */

export default class Texts {
	static tutorialIntro = 'Tohle je můj kancl. Ježkovy voči, to je ale chaos. Musím to tu dát do pucu.';
	static tutorialIntro2 = 'Nejdřív musím najít svoji moudrou knihu. Kam jsem ji zase zašantročil?';
	static tutorialBookFound = 'Nástroje aktivního občana by měl znát každý. Akorát mi tu chybí pár stránek. Ty ještě musím pohledat.';
	static tutorialBookClosed = 'Taky jsem ztratil rodokmen. Já hlava děravá.';
	static tutorialFamilyTreeFound = 'Tihle všichni jsou v té záhadě namočení. Jenom jsem zapomněl, kdo je kdo. Snad si časem vzpomenu.';
	static tutorialFamilyTreeClosed = 'Ještě si musím zapnout stopky. Ne nadarmo mi říkají Standa rychlovka.';
	static tutorialClockRunning = 'Čas běží. Dneska překonám svůj osobák.';

	static firstLetterNoOpen = 'Ještě není ta správná chvíle.';
	static firstLetterOpen = 'Já jsem ale trubka! Teď to musím posbírat.';
	static firstBlackmailAppear = 'Pro pánajána, co to sem přilítlo za ptáka!';
	static computerStarted = 'Tak už se to načítá. Starý dobrý Windows 95, skvělý ročník.';
	static computerRead = 'Ježkovy voči, zase jsem zapomněl heslo. Ještěže tady mám tu nápovědu.';
	static computerCorrectPassword2 = 'A jéje, zase nějaké aktualizace. Mezitím zpátky k případu.';
	static computerUpdating = 'Pořád se to aktualizuje. Už jich zbývá jen 32.';
	static firstMainGoal = 'Ha! Tohle je největší záhada mého pátrání. Koho si to ten Mário vůbec bere?';
	static firstMainGoalClicked1 = 'Můj hlavní úkol je najít tuhle osobu.';
	static firstFamilyTreeBlock = 'Než budu pátrat dál, musím si doplnit rodokmen. Už dovedu identifikovat 3 podezřelé!';
	static firstCanOpenLetter = 'Ajo, ještě musím otevřít tu obálku ze Švédska.';
	static firstFamilyTreeBlock2 = 'Hned jak se mi podaří otevřít ten dokument, musím si doplnit seznam podezřelých!';

	static secondNightStart = 'A je tu noc. Padla rychle jak v severské detektivce.';
	static secondNewspaperFound = 'No konečně, můj denní tisk dorazil! Pošťák chodí čím dál později.';
	static secondTrapdoor = 'Jauvajs, snad nebudu mít bouli.';
	static secondDiaryRead = 'Safra, je to zamčené. Někdo si to pořádně zabezpečil.';
	static secondDiaryNoUnlock = 'Nene, vždyť ten kód neznám a na tipování nemám čas.';
	static computerInvalidMail = 'Počkat, takhle to odeslat nemůžu. Nějak jsem to popletl.';
	static computerCorrectMail = 'Tak odesláno. Snad sebou na úřadě hodí.';
	static computerWaitingForAnswer = 'Kdepak, odpověď pořád nikde.';
	static secondDayStart = 'Dobré jitro. Helemese, odpověď z úřadu je tady!';
	static computerReplyRead = 'Ták vytisknu si to, ať v tom mám pořádek.';
	static computerReplyAlreadyRead = 'Kdepak, můj screentime už jsem si pro dnešek vyčerpal.';
	static thirdDiaryCanOpen = 'Teď už vím vše pro to, abych šel a odemknul ten deníček, myslím.';
	static thirdAllEvidence = 'Tak, shromáždil jsem všechny důkazy. Teď už znám odpověď na hlavní otázku případu.';
	static finished1 = 'Tak tohle jsem nečekal! Celou dobu je v tom namočená moje Lucinka!';
}
