import { Objects, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Utils from '../utils/Utils';

class PhotographOverlay extends BaseOverlay {
	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager, true);
		this.photograph = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.photographBasic);
		this.assetContainer.add(this.photograph);
		this.photographReverse = this.uiHandler
			.createImage(Utils.centerX(), Utils.centerY(), UIImages.photographReverse);
		this.assetContainer.add(this.photographReverse);
		this.hide(false);
	}

	hide(hiddenByPlayer = true) {
		super.hide(hiddenByPlayer);
		this.photograph.setVisible(this.visible);
		this.photographReverse.setVisible(this.visible);

		if (hiddenByPlayer && this.scene.gameState.photographFound === false) {
			this.scene.gameState.photographFound = true;
			this.scene.objectsManager.appearAnimation(Objects.photographLocated);
		}
	}

	show() {
		this.isFront = true;
		super.show();
		this.photograph.setVisible(this.visible);
	}

	rotate() {
		super.rotate();
		this.photograph.setVisible(this.isFront === true);
		this.photographReverse.setVisible(this.isFront === false);
	}
}

export default PhotographOverlay;
