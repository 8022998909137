class Loader {
	constructor(scene) {
		this.scene = scene;
	}

	loadingBar() {
		const loadingBar = this.scene.add.graphics();
		const progressBarWidth = 400;
		const progressBarHeight = 30;

		this.scene.load.on('progress', (progress) => {
			loadingBar.clear();
			loadingBar.fillStyle(0xffffff, 1);
			loadingBar.fillRect(
				this.scene.cameras.main.width / 2 - progressBarWidth / 2,
				this.scene.cameras.main.height / 2 - progressBarHeight / 2,
				progressBarWidth * progress,
				progressBarHeight,
			);
		});

		this.scene.load.on('complete', () => {
			loadingBar.destroy();
		});
	}

	preloadAssets(callback = null) {
		this.loadingBar();
		this.scene.load.json('audioList', `${process.env.PUBLIC_URL}/assets/audio/audioList.json`);
		this.scene.load.json('imageList', `${process.env.PUBLIC_URL}/assets/images/imageList.json`);
		this.scene.load.json('spriteList', `${process.env.PUBLIC_URL}/assets/spritesheets/spriteList.json`);
		this.scene.load.json('animationList', `${process.env.PUBLIC_URL}/assets/animations/animationList.json`);
		this.scene.load.json('hints', `${process.env.PUBLIC_URL}/assets/texts/text_set_1.json`);

		this.scene.load.video('introVideo', `${process.env.PUBLIC_URL}/assets/video/intro-video.mp4`);

		this.scene.load.on('filecomplete-json-audioList', (key, type, data) => {
			const { folders } = data;

			Object.keys(folders).forEach((folderKey) => {
				folders[folderKey].forEach((audioFile) => {
					this.scene.load.audio(audioFile.key, `${process.env.PUBLIC_URL}/assets/audio/${folderKey}/${audioFile.fileName}`);
				});
			});
		});

		this.scene.load.on('filecomplete-json-imageList', (key, type, data) => {
			const { folders } = data;
			Object.keys(folders).forEach((folderKey) => {
				folders[folderKey].forEach((imageFile) => {
					if (imageFile.fileName.includes('.svg')) {
						this.scene.load.svg(
							imageFile.key,
							`${process.env.PUBLIC_URL}/assets/images/${folderKey}/${imageFile.fileName}`,
							{ scale: imageFile.scale },
						);
					} else {
						this.scene.load.image(imageFile.key, `${process.env.PUBLIC_URL}/assets/images/${folderKey}/${imageFile.fileName}`);
					}
				});
			});
		});

		this.scene.load.on('filecomplete-json-spriteList', (key, type, data) => {
			const { folders } = data;

			Object.keys(folders).forEach((folderKey) => {
				folders[folderKey].forEach((spriteFile) => {
					this.scene.load.spritesheet(
						spriteFile.key,
						`${process.env.PUBLIC_URL}/assets/spritesheets/${folderKey}/${spriteFile.fileName}`,
						{ frameWidth: spriteFile.frameWidth, frameHeight: spriteFile.frameHeight },
					);
				});
			});
		});

		this.scene.load.on('filecomplete-json-animationList', (key, type, data) => {
			const { folders } = data;

			Object.keys(folders).forEach((folderKey) => {
				folders[folderKey].forEach((animationFile) => {
					this.scene.load.atlas(
						animationFile.key,
						`${process.env.PUBLIC_URL}/assets/animations/${folderKey}/${animationFile.fileName}`,
						`${process.env.PUBLIC_URL}/assets/animations/${folderKey}/${animationFile.dataName}`,
					);
				});
			});
		});

		if (callback) {
			this.scene.load.on('complete', () => {
				callback();
			});
		}
	}
}

export default Loader;
