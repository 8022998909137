import Hints from '../utils/Hints';
import GameState from '../gameSceneHelpers/GameState';

class HintManager {
	gameState = new GameState();

	previousGameState = null;

	timeInCurrentState = 0;

	alreadyPlayedHints = [];

	constructor(scene) {
		this.scene = scene;
	}

	isFreePlay() {
		return this.scene.canInput() === true
			&& this.scene.dialogueManager.visible === false
			&& this.scene.dialogueManager.queue.length === 0
			&& this.scene.animationManager.animationPlaying === false;
	}

	resetTimeInCurrentState() {
		this.timeInCurrentState = 0;
	}

	update(delta) {
		// Get current game state and reset hint timer if state has changed
		if (this.isFreePlay() === true) {
			this.timeInCurrentState += delta / 1000;
		}

		this.gameState = { ...this.scene.gameState };
		this.gameState.elapsedTime = 0; // Change of time is not considered a change of gameState

		if (JSON.stringify(this.gameState) !== this.previousGameState) {
			this.previousGameState = JSON.stringify(this.gameState);
			this.timeInCurrentState = 0;
		}

		// If unable to show hint, return
		if (this.isFreePlay() === false) {
			return;
		}

		this.checkNotFoundHints();
		this.checkFamilyTreeHints();
		this.checkComputerLoginHints();
		this.checkPdfHints();
		this.checkDiaryHints();
	}

	checkDiaryHints() {
		const condition = this.gameState.diaryRead === true
			&& this.gameState.conversationRead === true
			&& this.gameState.diaryOpened === false;

		if (this.alreadyPlayedHints.includes(Hints.contract1) === false && this.gameState.bookPage4Found === false) {
			this.tryHint(Hints.contract1, 30, 'n22_contract1', condition, true);
		} else if (this.alreadyPlayedHints.includes(Hints.contract2) === false) {
			this.tryHint(Hints.contract2, 30, 'n23_contract2', condition, true);
		} else {
			this.tryHint(Hints.contract3, 30, null, condition);
		}
	}

	checkPdfHints() {
		const condition = this.gameState.mailRead === true
			&& this.scene.familyTreeManager.isImportant === true
			&& this.gameState.brynolfFilled === false;

		if (this.alreadyPlayedHints.includes(Hints.pdf1) === false) {
			this.tryHint(Hints.pdf1, 30, 'n12_pdf1', condition, true);
		} else if (this.alreadyPlayedHints.includes(Hints.pdf2) === false) {
			this.tryHint(Hints.pdf2, 30, 'n13_pdf2', condition, true);
		} else {
			this.tryHint(Hints.pdf3, 30, 'n14_pdf3', condition);
		}
	}

	checkComputerLoginHints() {
		const condition = this.gameState.computerStarted === true
			&& this.gameState.computerLoggedIn === false
			&& (this.scene.isInOverlay === false || this.scene.computerDetail.visible === true);

		if (this.alreadyPlayedHints.includes(Hints.login1) === false) {
			this.tryHint(Hints.login1, 30, 'n05_login1', condition, true);
		} else if (this.alreadyPlayedHints.includes(Hints.login2) === false) {
			this.tryHint(Hints.login2, 30, 'n06_login2', condition, true);
		} else {
			this.tryHint(Hints.login3, 30, 'n07_login3', condition);
		}
	}

	checkFamilyTreeHints() {
		if (
			this.scene.familyTreeManager.isImportant === false
			|| (this.scene.isInOverlay === true && this.scene.familyTreeManager.visible === false)
		) {
			return;
		}

		const notYetFilled = [];
		if (this.gameState.lucieFilled === false && this.gameState.idRead === true) {
			notYetFilled.push({
				text: Hints.lucie,
				audioKey: 'n08_lucie',
			});
		}
		if (this.gameState.davidFilled === false && this.gameState.computerLoggedIn === true) {
			notYetFilled.push({
				text: Hints.david,
				audioKey: 'n09_david',
			});
		}
		if (this.gameState.ninaFilled === false && this.gameState.computerLoggedIn === true) {
			notYetFilled.push({
				text: Hints.nina,
				audioKey: 'n10_nina',
			});
		}
		if (this.gameState.lindaFilled === false && this.gameState.mailRead === true) {
			notYetFilled.push({
				text: Hints.linda,
				audioKey: 'n17_linda',
			});
		}
		if (
			this.gameState.hansFilled === false
			&& (this.gameState.mailRead === true || this.gameState.translationRead === true)) {
			notYetFilled.push({
				text: Hints.hans,
				audioKey: 'n15_hans',
			});
		}
		if (this.gameState.brynolfFilled === false && this.gameState.mailRead === true) {
			notYetFilled.push({
				text: Hints.brynolf,
				audioKey: 'n16_brynolf',
			});
		}
		if (this.gameState.marcelaFilled === false
			&& this.gameState.newspaperRead === true
			&& this.gameState.businessCardRead === true) {
			notYetFilled.push({
				text: Hints.marcela,
				audioKey: 'n25_marcela',
			});
		}
		if (this.gameState.marioFilled === false && this.gameState.conversationRead === true) {
			notYetFilled.push({
				text: Hints.mario,
				audioKey: 'n26_mario',
			});
		}

		const random = Math.floor(Math.random() * notYetFilled.length);
		this.tryHint(
			notYetFilled[random].text,
			30,
			notYetFilled[random].audioKey,
		);
	}

	checkNotFoundHints() {
		// Office only hints
		if (this.scene.isInOverlay === false) {
			this.tryHint(
				Hints.bookNotFound,
				30,
				'n01_bookNotFound',
				this.gameState.bookFound === false,
			);
			this.tryHint(
				Hints.familyTreeNotFound,
				30,
				'n02_familyTreeNotFound',
				this.gameState.bookFound === true && this.gameState.familyTreeFound === false,
			);
			this.tryHint(
				Hints.clockNotFound,
				30,
				'n03_clockNotFound',
				this.gameState.familyTreeFound === true && this.gameState.clockRunning === false,
			);
			this.tryHint(
				Hints.plugNotFound,
				30,
				'n04_plugNotFound',
				this.gameState.clockRunning === true && this.gameState.computerStarted === false,
			);
			this.tryHint(
				Hints.trapdoorNotFound,
				30,
				'n21_trapdoorNotFound',
				this.gameState.secondChapter === true && this.gameState.trapdoorClicked === false,
			);
		}

		// Office or letter
		if (this.scene.isInOverlay === false || this.scene.letterDetail.visible === true) {
			this.tryHint(
				Hints.letterNotOpened,
				15,
				'n11_letterNotOpened',
				this.scene.canOpenLetter === true && this.gameState.letterOpened === false,
			);
		}
	}

	tryHint(key, requiredTime, audioKey, condition = true, unique = false) {
		if (condition === true && requiredTime <= this.timeInCurrentState) {
			this.showHint(key, audioKey, unique);
		}
	}

	showHint(key, audioKey, unique = false) {
		if (unique === true && this.alreadyPlayedHints.includes(key)) {
			return;
		}

		this.timeInCurrentState = 0;
		this.alreadyPlayedHints.push(key);
		this.scene.singleDialogLine(key, audioKey, 0);
	}
}

export default HintManager;
