/* eslint-disable lines-between-class-members */

export default class Hints {
	static bookNotFound = 'Přece se ta Moudrá kniha nepropadla do země!';
	static familyTreeNotFound = 'Kam jsem ten rodokmen jenom dal?';
	static clockNotFound = 'Moje kukačkové hodiny po pradědovi mi změří čas pátrání nejpřesněji.';

	static plugNotFound = 'Třeba bych mohl zkusit využít ty internety.';
	static login1 = 'Moudrá kniha mi určitě napoví, jaký nástroj použít.';
	static login2 = 'Musím prověřit tu adresu z obálky.';
	static login3 = 'Do katastru musím zadat adresu z toho podezřelého psaní.';
	static lucie = 'Někam bych si měl poznamenat Lucinku, moji oblíbenou dcerušku.';
	static david = 'Ještě mi v rodokmenu chybí Lucinčin manžel David, křivák jeden.';
	static nina = 'Měl bych si zanést do rodokmenu i tu ženskou. Kdo ví, co s ní David má.';

	static letterNotOpened = 'Zatraceně, ještě jsem neotevřel ten divný dopis.';
	static pdf1 = 'Ještěže paní tlumočnice poslala ten odkaz. Takové informace se budou hodit.';
	static pdf2 = 'Vždy, když nevím, mrknu do Moudré knihy. Každou zašantročenou stránku určitě použiju.';
	static pdf3 = 'Na seznamu znalců najdu jakéhokoliv tlumočníka.';
	static hans = 'V rodokmenu mi chybí to nebohé dítě z rozvedené rodiny.';
	static brynolf = 'Musím si do rodokmenu doplnit toho severského taťku.';
	static linda = 'Ještě mi v rodokmenu chybí ta tlumočnice.';

	static mail1 = 'Měl bych si dát pozor, ať email splňuje všechny náležitosti.';
	static mail2 = 'Jak má správná žádost o informace vypadat, mám vypsané v Moudré knize.';
	static mailInvalid = 'Správnou emailovou adresu si vyčtu z té vizitky.';
	static trapdoorNotFound = 'Myslím, že mi brzo dojdou nakládané okurky, snad jsou ještě nějaké na půdě.';

	static contract1 = 'Ještě mi chybí jedna stránka Moudré knihy. Kde se asi válí?';
	static contract2 = 'Na Hlídači státu si proklepnu tu zakázku, o které mluvil Mário.';
	static contract3 = 'Když zadám číslo smlouvy do Hlídače státu, zjistím datum podepsání - takže i heslo do růžového deníčku.';
	static marcela = 'I paní z úřadu bych si měl zanést do rodokmenu.';
	static mario = 'V rodokmenu mi ještě chybí jméno toho notorického vyděrače, který vyděsil mě i Marcelku.';
}
