import Config from './Config';

class Utils {
	static centerX() {
		return Config.windowWidth / 2;
	}

	static centerY() {
		return Config.windowHeight / 2;
	}

	static xPercent(percent) {
		return Config.windowWidth * (percent / 100);
	}

	static yPercent(percent) {
		return Config.windowHeight - Config.windowHeight * (percent / 100);
	}

	static xToPercent(x) {
		return (x / Config.windowWidth) * 100;
	}

	static yToPercent(y) {
		return 100 - (y / Config.windowHeight) * 100;
	}

	static elapsedTimeToReadableString(elapsedTime) {
		let minutes = Math.floor(elapsedTime / 60);
		minutes = minutes.toString().padStart(2, '0');
		let seconds = elapsedTime % 60;
		seconds = seconds.toString().padStart(2, '0');
		return `${minutes}:${seconds}`;
	}

	static getRandomInt(min, max) {
		const minCeiled = Math.ceil(min);
		const maxFloored = Math.floor(max) + 1;
		return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
	}

	static removeAccents(string) {
		return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}
}

export default Utils;
