/* global window */

import Phaser from 'phaser';

import AssetHandler from '../classes/AssetHandler';
import UiHandler from '../classes/UiHandler';
import DebugInfo from '../utils/debug';
import AudioManager from '../classes/AudioManager';
import DialogueManager from '../classes/DialogueManager';
import Config from '../utils/Config';
import Loader from '../classes/Loader';
import PlayerControls from '../utils/PlayerControls';
import GameState from '../gameSceneHelpers/GameState';
import BackendBridge from '../utils/BackendBridge';

class BaseScene extends Phaser.Scene {
	commonPreload() {
		this.gameState = new GameState();
		this.playerCode = new URLSearchParams(window.location.search).get('code');
		this.loader = new Loader(this);
		this.loader.preloadAssets(() =>	{
			this.loadPlayerData();
		});
		// TODO Save into bool whether assets were already loaded. Pass that in loadScene function data and skip reload

		if (Config.debug === true) {
			this.load.scenePlugin(
				'PhaserDebugDrawPlugin',
				'https://cdn.jsdelivr.net/npm/phaser-plugin-debug-draw@7.1.0',
				'debugDraw',
				'debugDraw',
			);
		}
	}

	commonCreate() {
		if (Config.debug === true) {
			DebugInfo(this.scene);

			this.input
				.on('gameobjectover', (pointer, gameObject) => {
					gameObject.setTint(0x00ffff);
				})
				.on('gameobjectout', (pointer, gameObject) => {
					gameObject.clearTint();
				})
				.on('gameobjectdown', (pointer, gameObject) => {
					gameObject.setTint(0xff0000);
				})
				.on('gameobjectup', (pointer, gameObject) => {
					gameObject.clearTint();
				});
		}

		this.audioManager = new AudioManager(this);
		this.assetHandler = new AssetHandler(this);
		this.uiHandler = new UiHandler(this, this.assetHandler.assetContainer);
		this.dialogueManager = new DialogueManager(this, this.uiHandler, this.audioManager);
		PlayerControls.setDefaultCursor(this);
	}

	loadScene(sceneKey, data) {
		this.scene.start(sceneKey, data);
	}

	delayedExecute(delay, callback) {
		this.time.addEvent({
			delay: Config.fastDialogues === true ? 1000 : delay * 1000, // milliseconds to seconds
			callback,
		});
	}

	static isInputDisabled() {
		return !PlayerControls.input;
	}

	disableInput() {
		PlayerControls.disableControl(this);
	}

	enableInput() {
		PlayerControls.enableControl(this);
	}

	canInput() {
		return this.input.mouse.enabled === true;
	}

	singleDialogLine(textId, audioKey, priority, callback = null) { // TODO probably move
		this.dialogueManager.addToQueue(textId, audioKey, priority, callback);
	}

	loadPlayerData() {
		BackendBridge.getGameStateByPlayerCode(this.playerCode).then((result) => {
			this.player = result;
			this.onPlayerLoaded();
		});
	}
}

export default BaseScene;
