import PlayerControls from '../utils/PlayerControls';

class AssetHandler {
	constructor(scene) {
		this.scene = scene;
		this.assetContainer = this.scene.add.container(0, 0);
		this.assetContainer.setDepth(Number.MAX_SAFE_INTEGER);
		this.assetContainer.cacheAsBitmap = true;
		this.assetContainer.setActive(false);
	}

	addAssetElement(element) {
		// eslint-disable-next-line no-param-reassign
		element.cacheAsBitmap = true;
		// eslint-disable-next-line no-param-reassign
		element.setActive(true);
		this.assetContainer.add(element);
	}

	createImage(x, y, imageKey, frameIndex = null) {
		const imageElement = this.scene.add.image(x, y, imageKey, frameIndex);
		this.addAssetElement(imageElement);

		return imageElement;
	}

	createText(x, y, text) {
		const textElement = this.scene.add.text(x, y, text, { fontFamily: 'Merriweather' });
		this.addAssetElement(textElement);

		return textElement;
	}

	createSprite(x, y, spriteKey) {
		const spriteElement = this.scene.add.sprite(x, y, spriteKey);
		this.addAssetElement(spriteElement);

		return spriteElement;
	}

	createButton(x, y, spriteKey, onClick) {
		const buttonElement = this.createSprite(x, y, spriteKey);
		AssetHandler.makeInteractive(buttonElement, onClick);
		this.addAssetElement(buttonElement);

		return buttonElement;
	}

	static makeInteractive(asset, onClick) {
		asset.setInteractive();
		if (onClick) {
			asset.on('pointerdown', () => {
				if (!PlayerControls.input) {
					return;
				}
				onClick();
			});
		}
	}
}

export default AssetHandler;
