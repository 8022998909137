import { Objects, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Utils from '../utils/Utils';

class BusinessCardOverlay extends BaseOverlay {
	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager);
		this.businessCard = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.businessCardBasic);
		this.assetContainer.add(this.businessCard);
		this.hide(false);
	}

	hide(hiddenByPlayer = true) {
		super.hide();
		this.businessCard.setVisible(this.visible);
		this.businessCard.disableInteractive();

		if (hiddenByPlayer && this.scene.gameState.businessCardFound === false) {
			this.scene.gameState.businessCardFound = true;
			this.scene.objectsManager.appearAnimation(Objects.businessCardLocated);
		}
	}

	show() {
		super.show();
		this.businessCard.setVisible(this.visible);
		this.businessCard.setInteractive();
	}
}

export default BusinessCardOverlay;
