/* global document, window */

const exportButton = document.querySelector('.js-export-csv');

if (exportButton) {
	exportButton.addEventListener('click', () => {
		const classes = document.querySelectorAll('.js-export-csv-class');
		const codes = document.querySelectorAll('.js-export-csv-code');

		if (classes && codes) {
			const data = [['Třída', 'Kód']];

			classes.forEach((element, index) => {
				data.push([element.dataset.value, codes[index].dataset.value]);
			});

			let csvContent = '';

			data.forEach((row) => {
				csvContent += row.join(',');
				csvContent += '\n';
			});

			const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
			const objUrl = URL.createObjectURL(blob);
			const link = document.createElement('a');

			link.setAttribute('href', objUrl);
			link.setAttribute('download', 'NFPK-kódy.csv');
			document.body.appendChild(link);
			link.click();
			window.URL.revokeObjectURL(objUrl);
		}
	});
}
