/* global window */
/* eslint-disable no-console */
class BackendBridge {
	static getUrl = window.location;

	static baseApiUrl = 'api';

	static baseUrl = `${this.getUrl.protocol}//${this.getUrl.host}/${this.baseApiUrl}`;

	static async updateGameStateByPlayerCode(playerCode, gameState, callback = null) {
		const endpointUrl = `${this.baseUrl}/update-game-state/${playerCode}`;

		const data = new FormData();
		data.append('gameState', JSON.stringify(gameState));

		await fetch(endpointUrl, {
			method: 'POST',
			body: data,
		})
			.then((response) => {
				if (!response.ok) {
					response.json().then((errData) => {
						const error = new Error('Server responded with an error!');
						error.data = errData;
						throw error;
					});
				} else if (response.ok && callback) {
					callback();
				}
			})
			.catch((error) => {
				throw error;
			});
	}

	static async getGameStateByPlayerCode(playerCode) {
		const endpointUrl = `${this.baseUrl}/get-game-state/${playerCode}`;
		const response = await fetch(endpointUrl)
			.then((res) => {
				if (!res.ok) {
					return res.json().then((errData) => {
						const error = new Error('Server responded with an error!');
						error.data = errData;
						throw error;
					});
				}
				return res.json();
			})
			.catch((error) => {
				throw error;
			});

		return response;
	}

	static async getHighScoreByCode(playerCode) {
		const endpointUrl = `${this.baseUrl}/get-high-score/${playerCode}`;
		const response = await fetch(endpointUrl)
			.then((res) => {
				if (!res.ok) {
					return res.json().then((errData) => {
						const error = new Error('Server responded with an error!');
						error.data = errData;
						throw error;
					});
				}
				return res.json();
			})
			.catch((error) => {
				throw error;
			});

		return response;
	}
}

export default BackendBridge;
