import { Objects, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Utils from '../utils/Utils';

class ConversationOverlay extends BaseOverlay {
	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager);
		this.conversation = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.conversationBasic)
			.setScale(0.5);
		this.assetContainer.add(this.conversation);
		this.hide(false);
	}

	hide(hiddenByPlayer = true) {
		super.hide(hiddenByPlayer);
		this.conversation.setVisible(this.visible);
		this.conversation.disableInteractive();

		if (hiddenByPlayer && this.scene.gameState.conversationFound === false) {
			this.scene.gameState.conversationFound = true;
			this.scene.objectsManager.appearAnimation(Objects.conversationLocated);
		}
	}

	show() {
		super.show();
		this.conversation.setVisible(this.visible);
		this.conversation.setInteractive();
	}
}

export default ConversationOverlay;
