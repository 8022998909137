/* eslint-disable lines-between-class-members */
import BaseScene from './BaseScene';
import {
	Scenes, Objects, SFX, State,
} from '../utils/Consts';
import Texts from '../utils/Texts';
import BookOverlay from '../overlays/BookOverlay';
import FamilyTreeOverlay from '../overlays/FamilyTreeOverlay';
import Utils from '../utils/Utils';
import GOContainer from '../gameSceneHelpers/GOContainer';
import InteractHandler from '../gameSceneHelpers/InteractHandler';
import ObjectsManager from '../gameSceneHelpers/ObjectsManager';
import UITimer from '../gameSceneHelpers/UITimer';
import PhotographOverlay from '../overlays/PhotographOverlay';
import IdOverlay from '../overlays/IdOverlay';
import LetterOverlay from '../overlays/LetterOverlay';
import BlackmailOverlay from '../overlays/BlackmailOverlay';
import ComputerOverlay from '../overlays/ComputerOverlay';
import TranslationOverlay from '../overlays/TranslationOverlay';
import MailOverlay from '../overlays/MailOverlay';
import BusinessCardOverlay from '../overlays/BusinessCardOverlay';
import NewspaperOverlay from '../overlays/NewspaperOverlay';
import DiaryOverlay from '../overlays/DiaryOverlay';
import ReplyOverlay from '../overlays/ReplyOverlay';
import ConversationOverlay from '../overlays/ConversationOverlay';
import MainGoalOverlay from '../overlays/MainGoalOverlay';
import AnimationManager from '../classes/AnimationManager';
import HintManager from '../classes/HintManager';
import BackendBridge from '../utils/BackendBridge';
import GameState from '../gameSceneHelpers/GameState';

class GameScene extends BaseScene {
	isInOverlay = false;
	firstChapterStarted = false;
	blackmailTimerStarted = false;
	canOpenLetter = false;
	canBeShownSecondFamilyTreeBlock = false;
	canOpenDiary = false;
	finalQuestion = false;
	thirdChapterAnimationStarted = false;
	replyGuard = false;

	constructor() {
		super({ key: Scenes.gameScene });
	}

	preload() {
		this.commonPreload();
	}

	onPlayerLoaded() {
		this.gameState.playerCode = this.playerCode;
		if (this.player.data !== null) {
			this.gameState.loadFromPlayer(this.player);
		}
		if (this.player.state === State.FINISHED) {
			this.loadScene(Scenes.outroScene, { gameState: this.gameState, player:	this.player });
		} else {
			this.time.addEvent({
				delay: 5000, // 5 seconds
				callback: () => BackendBridge.updateGameStateByPlayerCode(this.playerCode, this.gameState),
				loop: true,
			});
		}
	}

	create(data) {
		// ================== References Init ==================
		this.commonCreate();
		this.gameState = data.gameState ?? new GameState();
		this.GOContainer = new GOContainer(this);
		this.interactHandler = new InteractHandler(this);
		this.objectsManager = new ObjectsManager(this);
		this.hintManager = new HintManager(this);

		// Overlays
		this.bookManager = new BookOverlay(this, this.uiHandler, this.audioManager);
		this.familyTreeManager = new FamilyTreeOverlay(this, this.uiHandler, this.audioManager);
		this.photographDetail = new PhotographOverlay(this, this.uiHandler, this.audioManager);
		this.idDetail = new IdOverlay(this, this.uiHandler, this.audioManager);
		this.letterDetail = new LetterOverlay(this, this.uiHandler, this.audioManager);
		this.blackmailDetail = new BlackmailOverlay(this, this.uiHandler, this.audioManager);
		this.computerDetail = new ComputerOverlay(this, this.uiHandler, this.audioManager);
		this.translationDetail = new TranslationOverlay(this, this.uiHandler, this.audioManager);
		this.mailDetail = new MailOverlay(this, this.uiHandler, this.audioManager);
		this.businessCardDetail = new BusinessCardOverlay(this, this.uiHandler, this.audioManager);
		this.newspaperDetail = new NewspaperOverlay(this, this.uiHandler, this.audioManager);
		this.diaryDetail = new DiaryOverlay(this, this.uiHandler, this.audioManager);
		this.replyDetail = new ReplyOverlay(this, this.uiHandler, this.audioManager);
		this.conversationDetail = new ConversationOverlay(this, this.uiHandler, this.audioManager);
		this.mainGoalDetail = new MainGoalOverlay(this, this.uiHandler, this.audioManager);

		this.audioManager.startBackgroundMusic();
		this.inputSetup();
		this.visualsSetup();
		this.animationManager = new AnimationManager(this); // Needs to be after visualsSetup
		this.UITimer = new UITimer(this);

		// Fix for when loading the game
		if (Object.keys(data).length !== 0) {
			this.animationManager.playIdle();
		}

		// ================== Tutorial ==================
		if (this.gameState.bookFound === false) {
			this.disableInput();
			this.delayedExecute(2, () => {
				this.animationManager.playDropIn(() => {
					this.animationManager.playIdle();
					this.dialogueManager.addToQueue(Texts.tutorialIntro, '01-tutorialIntro', 0);
					this.dialogueManager.addToQueue(Texts.tutorialIntro2, '02-tutorialIntro2', 0);
				});
			});
		}

		// ================== First Chapter ==================
		if (this.gameState.photographFound === true) {
			this.firstChapterStarted = true;
			this.objectsManager.reRender();
			this.animationManager.playIdle();
		}

		if (this.gameState.blackmailFound === true && this.gameState.mainGoalShowed === false) {
			this.delayedExecute(2.5, () => this.mainGoalAppearAnimation());
		}

		// ================== Second Chapter ==================
		if (this.gameState.secondChapter === true && this.gameState.thirdChapter === false) {
			this.secondChapterAnimation(true); // To start night mode
		}
		// ================== Third Chapter ==================
		if (this.gameState.thirdChapter === true) {
			this.thirdChapterAnimation(true); // To start second day mode
		}

		this.animationManager.initStaticAnimation();
	}

	update(time, delta) {
		this.hintManager.update(delta);
		this.dialogueManager.processQueue();
		this.audioManager.update();
		if (this.gameState.clockRunning === true && this.firstChapterStarted === false) {
			this.firstChapterStarted = true;
			this.objectsManager.reRender();
		}

		// Blackmail timer
		if (
			this.gameState.letterRead === true
			&& this.gameState.idRead === true
			&& this.gameState.photographRead === true
			&& this.gameState.blackmailAppeared === false
			&& this.blackmailTimerStarted === false
		) {
			this.blackmailTimerStarted = true;
			this.delayedExecute(15, () => this.blackmailAppearAnimation());
		}

		if (this.isInOverlay === true || this.hintManager.isFreePlay() === false) {
			return;
		}

		// Reply Received
		if (
			this.gameState.thirdChapter === true
			&& this.gameState.replyPrinted === false
			&& this.gameState.replyReceived === false
			&& this.replyGuard === false
		) {
			this.replyGuard = true;
			this.delayedExecute(1, () => {
				this.gameState.replyReceived = true;
				this.dialogueManager.addToQueue(Texts.secondDayStart, '28-secondDayStart', 0);
				this.objectsManager.reRender();
			});
		}

		// First Family Tree Block
		if (
			this.gameState.mainGoalShowed === true
			&& this.gameState.computerLoggedIn === true
			&& this.familyTreeManager.isImportant === false
			&& (
				this.gameState.davidFilled === false
			|| this.gameState.lucieFilled === false
			|| this.gameState.ninaFilled === false
			)
		) {
			this.familyTreeManager.isImportant = true;
			this.hintManager.resetTimeInCurrentState();
			this.objectsManager.reRender();
			this.dialogueManager.addToQueue(Texts.firstFamilyTreeBlock, '17-firstFamilyTreeBlock', 0);
		} else if (
			this.gameState.mainGoalShowed === true
			&& this.gameState.davidFilled === true
			&& this.gameState.lucieFilled === true
			&& this.gameState.ninaFilled === true
			&& this.canOpenLetter === false
			&& this.gameState.letterOpened === false
		) {
			this.canOpenLetter = true;
			this.dialogueManager.addToQueue(Texts.firstCanOpenLetter, '18-firstCanOpenLetter', 0);
		}

		// Second Family Tree Block
		if (
			this.canBeShownSecondFamilyTreeBlock === true
			&& this.gameState.mailRead === true
			&& this.familyTreeManager.isImportant === false
			&& (
				this.gameState.lindaFilled === false
				|| this.gameState.hansFilled === false
				|| this.gameState.brynolfFilled === false
			)
		) {
			this.familyTreeManager.isImportant = true;
			this.hintManager.resetTimeInCurrentState();
			this.objectsManager.reRender();
			this.dialogueManager.addToQueue(Texts.firstFamilyTreeBlock2, '19-firstFamilyTreeBlock2', 0);
		} else if (
			this.gameState.lindaFilled === true
			&& this.gameState.hansFilled === true
			&& this.gameState.brynolfFilled === true
			&& this.gameState.secondChapter === false
		) {
			this.secondChapterAnimation();
		}

		// Third chapter start
		if (
			this.gameState.diaryRead === true
			&& this.gameState.mailSent === true
			&& this.gameState.thirdChapter === false
			&& this.thirdChapterAnimationStarted === false
		) {
			this.thirdChapterAnimation();
		}

		// Final Family Tree Block - Mario
		if (
			this.gameState.conversationRead === true
			&& this.familyTreeManager.isImportant === false
			&& (this.gameState.marioFilled === false || this.gameState.marcelaFilled === false)
		) {
			this.familyTreeManager.isImportant = true;
			this.hintManager.resetTimeInCurrentState();
			this.objectsManager.reRender();
		}

		// Diary can open
		if (
			this.gameState.marcelaFilled === true
			&& this.gameState.marioFilled === true
			&& this.canOpenDiary === false
			&& this.gameState.diaryOpened === false
		) {
			this.canOpenDiary = true;
			this.dialogueManager.addToQueue(Texts.thirdDiaryCanOpen, null, 0);
		}

		if (
			this.finalQuestion === false
			&& this.gameState.diaryOpened === true
			&& this.gameState.marioFilled === true
			&& this.gameState.marcelaFilled === true
		) {
			this.finalQuestion = true;
			this.objectsManager.reRender();
			this.dialogueManager.addToQueue(Texts.thirdAllEvidence, '31-thirdAllEvidence', 0);
		}
	}

	secondChapterAnimation(skip = false) {
		if (skip === true) {
			this.GOContainer.allowLights();
			this.animationManager.allowLights();
			this.background.setPipeline('Light2D');
			this.background.setTexture(Objects.backgroundNight);
			this.hat.setVisible(false);
			this.lights.addLight(Utils.xPercent(49), Utils.yPercent(71), 75);
			this.lights.enable().setAmbientColor(0xE8AE38);
			this.animationManager.stopIdle(false, null);
			this.animationManager.playIdle(false, null);
		} else {
			this.disableInput();
			this.gameState.secondChapter = true;
			this.animationManager.dayToNight(() => {
				this.objectsManager.reRender();
				this.animationManager.stopIdle(false, null);
				this.animationManager.playIdle(false, null);
				this.GOContainer.allowLights();
				this.animationManager.allowLights();
				this.background.setPipeline('Light2D');
				this.background.setTexture(Objects.backgroundNight);
				this.hat.setVisible(false);
				this.UITimer.setLightPipeline();
				this.lights.addLight(Utils.xPercent(49), Utils.yPercent(71), 75);
				this.lights.enable().setAmbientColor(0xE8AE38);

				this.delayedExecute(2, () => {
					this.dialogueManager.addToQueue(Texts.secondNightStart, '20-secondNightStart', 0);
					this.delayedExecute(2, () => this.enableInput());
				});
			});
		}
	}

	thirdChapterAnimation(skip = false) {
		if (skip === true) {
			this.GOContainer.disableLights();
			this.animationManager.disableLights();
			this.background.resetPipeline();
			this.background.setTexture(Objects.background);
			this.hat.setVisible(true);
			this.UITimer.resetPipeline();
			this.lights.disable();
			this.animationManager.stopIdle(false, null);
			this.animationManager.playIdle(false, null);
		} else {
			// Do not allow transition when UI is shown
			if (this.thirdChapterAnimationStarted === true) {
				return;
			}
			if (this.hintManager.isFreePlay() === false) {
				this.delayedExecute(1, () => this.thirdChapterAnimation());
				return;
			}

			this.thirdChapterAnimationStarted = true;
			this.disableInput();

			this.animationManager.nightToDay(() => {
				this.gameState.thirdChapter = true;
				this.objectsManager.reRender();
				this.GOContainer.disableLights();
				this.animationManager.disableLights();
				this.background.resetPipeline();
				this.background.setTexture(Objects.background);
				this.hat.setVisible(true);
				this.UITimer.resetPipeline();
				this.animationManager.stopIdle(false, null);
				this.animationManager.playIdle(false, null);
				this.lights.disable();
				this.delayedExecute(1, () => {
					this.enableInput();
				});
			});
		}
	}

	mainGoalAppearAnimation() {
		// Only when in control and on main screen, otherwise try once second later
		if (this.isInOverlay === true || this.canInput() === false) {
			this.delayedExecute(1, () => this.mainGoalAppearAnimation());
			return;
		}

		this.disableInput();
		this.animationManager.showMainGoal(() => {
			this.enableInput();
			this.gameState.mainGoalShowed = true;
			this.objectsManager.reRender();
			this.dialogueManager.addToQueue(Texts.firstMainGoal, '15-firstMainGoal', 0);
		});
	}

	blackmailAppearAnimation() {
		// Only when in control and on main screen, otherwise try once second later
		if (this.isInOverlay === true || this.hintManager.isFreePlay() === false) {
			this.delayedExecute(3, () => this.blackmailAppearAnimation());
			return;
		}

		this.disableInput();
		this.animationManager.playAnimation(Objects.blackmail, null, () => {
			this.gameState.blackmailAppeared = true;
			this.objectsManager.reRender();
			this.dialogueManager.addToQueue(Texts.firstBlackmailAppear, '10-firstBlackmailAppear', 0);
		});
	}

	finalCutscene() {
		this.disableInput();
		this.gameState.finished = true;
		BackendBridge.updateGameStateByPlayerCode(this.playerCode, this.gameState);
		this.dialogueManager.addToQueue(Texts.finished1, '33-finished1', 0, () => {
			this.disableInput();
			this.audioManager.playOneShot(SFX.fanfare, 0.7);
			this.delayedExecute(1, () => this.GOContainer.fetchByName(Objects.familyTreeLocated, true, true));
			this.animationManager.playAnimation('outro', null, () => {
				this.animationManager.stopIdle();
				this.sound.pauseAll();
				this.delayedExecute(0.1, () => {
					this.loadScene(Scenes.outroScene, { gameState: this.gameState });
				});
			});
		});
	}

	isNight() {
		return this.gameState.secondChapter === true && this.gameState.thirdChapter === false;
	}

	visualsSetup() {
		this.background = this.assetHandler.createImage(Utils.centerX(), Utils.centerY(), Objects.background);
		this.hat = this.assetHandler.createImage(Utils.xPercent(88.2), Utils.yPercent(49.7), Objects.hat)
			.setScale(0.93);
		this.objectsManager.reRender();
	}

	inputSetup() {
		this.input.keyboard.enabled = false;
		this.input.on(
			'gameobjectdown',
			(pointer, gameObject) => {
				if (BaseScene.isInputDisabled()) {
					return;
				}
				this.interactHandler.interact(gameObject.name);
			},
		).on('gameobjectover', (pointer, gameObject) => {
			if (gameObject === this.dialogueManager.textBoxNext && this.dialogueManager.textBoxNext.visible) {
				this.enableInput();
			}
			if (BaseScene.isInputDisabled()) {
				return;
			}
			if (this.GOContainer.includes(gameObject)) {
				if (this.gameState.secondChapter === true) {
					gameObject.setTint(0x93C47D);
				} else {
					gameObject.setTint(gameObject.name === Objects.plug ? 0xe1e599 : 0xfbffab);
				}
			}
		}).on('gameobjectout', (pointer, gameObject) => {
			if (this.GOContainer.includes(gameObject)) {
				gameObject.clearTint();
			} else if (gameObject === this.dialogueManager.textBoxNext && this.dialogueManager.textBoxNext.visible) {
				this.disableInput();
			}
		});
	}
}

export default GameScene;
