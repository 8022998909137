import { Objects, SFX, UIImages } from '../utils/Consts';
import BaseOverlay from './BaseOverlay';
import Utils from '../utils/Utils';
import Texts from '../utils/Texts';

class LetterOverlay extends BaseOverlay {
	constructor(scene, uiHandler, audioManager) {
		super(scene, uiHandler, audioManager, true);
		this.letter = this.uiHandler.createImage(Utils.centerX(), Utils.centerY(), UIImages.letterBasic);
		this.assetContainer.add(this.letter);
		this.letterReverse = this.uiHandler
			.createImage(
				Utils.centerX(),
				Utils.centerY(),
				this.scene.gameState.letterOpened === false ? UIImages.letterReverse : UIImages.letterReverseOpened,
			);
		this.assetContainer.add(this.letterReverse);

		this.openButton = this.uiHandler.createButton(
			Utils.xPercent(95),
			Utils.yPercent(74),
			UIImages.openButton,
			() => this.tryOpen(),
			true,
		).setScale(0.5);
		this.assetContainer.add(this.openButton);
		this.hide(false);
	}

	hide(hiddenByPlayer = true) {
		super.hide();
		this.letter.setVisible(this.visible);
		this.letterReverse.setVisible(this.visible);
		this.openButton.setVisible(this.visible);

		if (hiddenByPlayer && this.scene.gameState.letterFound === false) {
			this.scene.gameState.letterFound = true;
			this.scene.objectsManager.appearAnimation(Objects.letterLocated);
		}
	}

	show() {
		this.isFront = true;
		super.show();
		this.letter.setVisible(this.visible);
	}

	rotate() {
		super.rotate();
		this.letter.setVisible(this.isFront === true);

		this.letterReverse.setVisible(this.isFront === false);
		this.openButton.setVisible(this.isFront === false && this.scene.gameState.letterOpened === false);
	}

	tryOpen() {
		this.scene.audioManager.playOneShot(SFX.uiClick, 0.3);		if (this.scene.gameState.letterOpened === true) {
			return;
		}
		if (this.scene.canOpenLetter === false) {
			this.scene.singleDialogLine(Texts.firstLetterNoOpen, '08-firstLetterNoOpen', 0);
		} else {
			this.letterReverse.destroy();
			this.letterReverse = this.uiHandler
				.createImage(Utils.centerX(), Utils.centerY(), UIImages.letterReverseOpened);
			this.assetContainer.add(this.letterReverse);
			this.hide();
			this.scene.singleDialogLine(Texts.firstLetterOpen, '09-firstLetterOpen', 0);

			this.scene.animationManager.openLetter(() => {
				this.scene.gameState.letterOpened = true;
				this.scene.objectsManager.reRender();
			});
		}
	}
}

export default LetterOverlay;
