/* global Phaser */

import { Music } from '../utils/Consts';

class AudioManager {
	shouldPlayBackgroundMusic = false;

	backgroundMusicTracks = [
		Music.game1,
		Music.game2,
		Music.game3,
		Music.game4,
		Music.game5,
		Music.game6,
		Music.game7,
	];

	constructor(scene) {
		this.scene = scene;
		this.backgroundMusic = null;
		this.soundEffects = [];
		this.defaultVolume = 1.0;
		this.backgroundVolume = 0.15;
		this.scene.sound.pauseOnBlur = false; // Continue playing when browser tab is inactive1
		this.dialogue = null;
		this.dialogueVolume = 1.15;

		const audioList = this.scene.cache.json.get('audioList');
		const { folders } = audioList;

		Object.keys(folders).forEach((folderKey) => {
			folders[folderKey].forEach((audioFile) => {
				this.scene.load.audio(audioFile, folderKey + audioFile);
			});
		});
	}

	playAudio(key, callBack = null) {
		this.audioShot = this.scene.sound.add(key, { volume: this.defaultVolume });
		this.audioShot.play();

		if (callBack) {
			this.audioShot.once('complete', () => {
				callBack();
			});
		}
	}

	startBackgroundMusic() {
		this.shouldPlayBackgroundMusic = true;
	}

	update() {
		if (this.shouldPlayBackgroundMusic === false) {
			if (this.backgroundMusic) {
				this.backgroundMusic.stop();
			}
			return;
		}

		if (
			this.backgroundMusic === null
			|| this.backgroundMusic === undefined
			|| this.backgroundMusic.hasEnded
			|| !this.backgroundMusic.isPlaying
		) {
			// select new random music track
			const selectedTrack = this.backgroundMusicTracks[
				Math.floor(Math.random() * this.backgroundMusicTracks.length)
			];
			this.backgroundMusic = this.scene.sound.add(
				selectedTrack,
				{ volume: this.backgroundVolume },
			);
			this.backgroundMusic.play();
		}
	}

	playBackgroundMusic(key) {
		if (this.backgroundMusic) {
			this.backgroundMusic.stop();
		}
		this.backgroundMusic = this.scene.sound.add(key, { volume: this.backgroundVolume, loop: true });
		this.backgroundMusic.play();
	}

	playBackgroundMusicRandom() {
		const musicKeys = Object.keys(this.scene.cache.audio.entries);
		if (musicKeys.length === 0) {
			return;
		}

		const randomKey = Phaser.Utils.Array.GetRandom(musicKeys);
		this.playBackgroundMusic(randomKey);
	}

	playOneShot(key, volume = null) {
		this.scene.sound.play(key, { volume: volume ?? this.defaultVolume });
	}

	playSoundEffect(key) {
		const soundEffect = this.scene.sound.add(key, { volume: this.defaultVolume });
		soundEffect.play();
		this.soundEffects.push(soundEffect);
	}

	stopAllSoundEffects() {
		this.soundEffects.forEach((soundEffect) => {
			soundEffect.stop();
		});
		this.soundEffects = [];
	}

	setDefaultVolume(volume) {
		this.defaultVolume = Phaser.Math.Clamp(volume, 0, 1);
	}

	setBackgroundVolume(volume) {
		this.backgroundVolume = Phaser.Math.Clamp(volume, 0, 1);
		if (this.backgroundMusic) {
			this.backgroundMusic.setVolume(this.backgroundVolume);
		}
	}

	playDialogue(key) {
		this.dialogue = this.scene.sound.add(key, { volume: this.dialogueVolume });
		this.dialogue.play();
	}

	skipDialogue() {
		if (this.dialogue !== null) {
			this.dialogue.stop();
		}
	}
}

export default AudioManager;
