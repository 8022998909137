export default function DebugInfo(info) {
	let debugText;
	let debugVisible = false;

	function updateDebugInfo() {
		debugText.setText(
			`Pointer X: ${info.scene.input.activePointer.x}, Y: ${info.scene.input.activePointer.y}\n`
			+ `Camera X: ${info.scene.cameras.main.scrollX}, Y: ${info.scene.cameras.main.scrollY}\n`,
		);
		debugText.setDepth(9999);
	}

	const rectWidth = 300;
	const rectHeight = 400;
	const rectX = info.scene.cameras.main.width - rectWidth;
	const rectY = 0;
	const debugRect = info.scene.add.rectangle(rectX, rectY, rectWidth, rectHeight, 0o00000);
	debugRect.setDepth(9998);
	debugRect.setAlpha(0.8);
	debugRect.setOrigin(0, 0);
	debugRect.setVisible(false);

	debugText = info.scene.add.text(rectX, rectY, '', {
		fontFamily: 'Arial',
		fontSize: '16px',
		fill: '#fff',
	});
	debugText.setVisible(false);

	info.scene.events.on('update', updateDebugInfo);

	function toggleDebug() {
		debugVisible = !debugVisible;
		debugText.setVisible(debugVisible);
		debugRect.setVisible(debugVisible);
	}

	info.scene.input.keyboard.on('keydown-D', () => {
		toggleDebug();
	});

	return debugText;
}
