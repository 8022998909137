/* global document, window */

import Phaser from 'phaser';
import InputTextPlugin from 'phaser3-rex-plugins/plugins/inputtext-plugin';
import RexUIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin';

import {
	IntroScene,
	GameScene,
	EndingScene,
} from './scenes/index';
import Config from './utils/Config';

const gameContainer = document.querySelector('.js-game-renderer');

if (gameContainer) {
	const config = {
		parent: gameContainer,
		dom: {
			createContainer: true,
		},
		fx: {
			glow: {
				distance: 32,
				quality: 0.1,
			},
		},
		powerPreference: 'high-performance',
		type: Phaser.AUTO,
		scene: [IntroScene, GameScene, EndingScene],
		scale: {
			mode: Phaser.Scale.FIT,
			autoCenter: Phaser.Scale.CENTER_BOTH,
			parent: gameContainer,
			width: Config.windowWidth,
			height: Config.windowHeight,
			autoRound: true,
		},
		physics: {
			default: null,
		},
		plugins: {
			scene: [
				{
					key: 'rexUI',
					plugin: RexUIPlugin,
					mapping: 'rexUI',
				},
			],
			global: [
				{
					key: 'Debug',
					mapping: 'debug',
					data: {
						color: 'white',
					},
				},
				{
					key: 'rexInputTextPlugin',
					plugin: InputTextPlugin,
					start: true,
				},
			],
		},
	};

	// Debug shortcuts
	if (Config.useMockupSave === true) {
		config.scene = [GameScene, IntroScene, EndingScene];
	}

	if (new URLSearchParams(window.location.search).has('outro')) {
		config.scene = [EndingScene];
	}

	if (new URLSearchParams(window.location.search).has('fast')) {
		Config.fastDialogues = true;
	}

	// eslint-disable-next-line no-unused-vars
	const game = new Phaser.Game(config);
}
