import BaseScene from './BaseScene';
import {
	Music, Scenes, SFX, State, UIImages,
} from '../utils/Consts';
import Utils from '../utils/Utils';
import Config from '../utils/Config';

class IntroScene extends BaseScene {
	constructor() {
		super({ key: Scenes.introScene });
	}

	preload() {
		this.commonPreload();
	}

	onPlayerLoaded() {
		if (this.player.state === State.UNUSED) {
			this.gameState.playerCode = this.playerCode;
		} else {
			// If game was already started, skip intro scene
			this.gameState.loadFromPlayer(this.player);
			this.sound.pauseAll();
			this.loadScene(this.player.state === State.PLAYING
				? Scenes.gameScene : Scenes.outroScene, { gameState: this.gameState });
		}
	}

	create() {
		this.commonCreate();
		this.audioManager.playOneShot(Music.intro, 0.5);
		this.introImage = this.assetHandler.createImage(Utils.centerX(), Utils.centerY(), 'introBackground')
			.setScale(1.05);

		this.textBox = this.rexUI.add.textBox({
			x: Utils.centerX(),
			y: Utils.yPercent(22),
			width: 1200,
			height: 250,
			background: this.assetHandler.createImage(
				Utils.centerX() - 400,
				Utils.yPercent(22),
				'introText',
			),
			text: this.rexUI.add.BBCodeText(0, 0, 'Vítám tě u detektivní hry, která tě naučí používat nástroje aktivního občana! Jsi připraven rozlousknout záhadu nevěry, korupce a tajné lásky? Nezapomeň si [b]zapnout zvuk[/b] a jdeme na to.', {
				fixedWidth: 1160,
				fixedHeight: 220,
				fontFamily: 'Merriweather',
				fontSize: '40px',
				wrap: {
					mode: 'word',
					width: 1160,
				},
				maxLines: 4,
			}).setDepth(9999),
			align: {
				text: 'left',
			},
			space: {
				left: 40,
				right: 20,
				top: 30,
				bottom: 20,
				text: 10,
			},
		})
			.setOrigin(0.5, 0.5)
			.setDepth(9998)
			.layout();

		const video = this.add.video(Utils.centerX(), Utils.centerY(), 'introVideo')
			.setOrigin(0.5, 0.5)
			.setTexture('gameBackground1')
			.setDisplaySize(Config.windowWidth, Config.windowHeight)
			.setScale(1);
		if (video) {
			video.on('complete', () => {
				this.startTheGame();
			});
		}

		const button = this.uiHandler.createButton(
			Utils.centerX(),
			Utils.yPercent(55),
			'introButton',
			() => {
				this.audioManager.playOneShot(SFX.uiClick, 0.3);
				this.sound.pauseAll();
				button.setVisible(false);
				this.textBox.setVisible(false);
				this.textBoxCredits.setVisible(false);
				this.buttonCredits.setVisible(false);
				video.play();
			},
			true,
		);

		this.textBoxCredits = this.rexUI.add.textBox({
			x: Utils.centerX(),
			y: Utils.yPercent(75),
			width: 1150,
			height: 320,
			background: this.assetHandler.createImage(
				Utils.centerX() - 400,
				Utils.yPercent(22),
				'introText',
			),
			text: this.rexUI.add.BBCodeText(0, 0, '[b]Námět a scénář:[/b] Barbora Cinádrová a Bára Kuriálová\n'
				+ '[b]Grafická podoba a úvodní video:[/b] Bára Kuriálová @tadybarka\n'
				+ '[b]Voiceover:[/b] Jakub Kuriál\n'
				+ '[b]Nahrávání a postprodukce voiceoveru:[/b] Michael Kuba\n'
				+ '[b]Vývoj a animace:[/b] Netvor s.r.o.\n\n'
				+ 'Velvet Sax by Boggart VGM, CC-BY 4.0, unmodified, opengameart.org/content/velvet-sax-three-arangments-solo-band-synth\n'
				+ 'Signs to Nowhere by Shane Ivers, CC-BY 4.0, unmodified, silvermansound.com/free-music/signs-to-nowhere\n'
				+ 'TroubleMakers by Matthew Pablo, CC-BY 3.0, unmodified, opengameart.org/content/trouble-makers-coolriff-jazz\n'
				+ 'Careful There by Justin Allan Arnold, CC-BY 4.0, unmodified, free-stock-music.com/justin-allan-arnold-careful-there.html\n'
				+ 'Private Infinities by Justin Allan Arnold, CC-BY 4.0, unmodified, free-stock-music.com/justin-allan-arnold-private-infinities.html\n'
				+ 'Noire#1 by Music By Pedro, youtube.com/watch?v=84ANGsQS_7w\n'
				+ 'CC-BY 4.0 creativecommons.org/licenses/by/4.0/, CC-BY 3.0 creativecommons.org/licenses/by/3.0/', {
				fixedWidth: 1110,
				fixedHeight: 290,
				fontFamily: 'Merriweather',
				fontSize: '20px',
				wrap: {
					mode: 'word',
					width: 1110,
				},
				maxLines: 16,
			}).setDepth(9999),
			align: {
				text: 'left',
			},
			space: {
				left: 40,
				right: 40,
				top: 20,
				bottom: 20,
				text: 10,
			},
		})
			.setOrigin(0.5, 0.5)
			.setVisible(false)
			.layout();

		this.buttonCredits = this.uiHandler.createButton(
			Utils.xPercent(3),
			Utils.yPercent(90),
			UIImages.nextButton,
			() => {
				this.audioManager.playOneShot(SFX.uiClick, 0.3);
				this.textBoxCredits.setVisible(!this.textBoxCredits.visible);
			},
			true,
		).setScale(0.5);

		this.uiHandler.addUiElement(this.textBoxCredits);
	}

	startTheGame() {
		this.delayedExecute(2, () => this.loadScene(Scenes.gameScene));
	}
}

export default IntroScene;
