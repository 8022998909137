/* global document */

const flashes = document.querySelectorAll('.js-flash');

function flashFade(flash) {
	flash.classList.remove('flash--visible');
	setTimeout(() => {
		flash.remove();
	}, 200);
}

flashes.forEach((flash) => {
	setTimeout(() => {
		flashFade(flash);
	}, 4500);
});
