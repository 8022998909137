/* eslint-disable no-case-declarations */
import { Objects } from '../utils/Consts';
import Texts from '../utils/Texts';

class InteractHandler {
	constructor(scene) {
		this.scene = scene;
	}

	// eslint-disable-next-line no-unused-vars
	interact(gameObjectName) {
		switch (gameObjectName) {
			case Objects.book:
				this.scene.animationManager.playAnimation(Objects.book, () => {
					this.scene.GOContainer.fetchByName(Objects.book, true, true);
				}, () => {
					this.scene.singleDialogLine(Texts.tutorialBookFound, '03-tutorialBookFound', 0, () => {
						this.interact(Objects.bookLocated);
					});
				});
				break;
			case Objects.bookLocated:
				this.scene.bookManager.show();
				if (this.scene.gameState.bookRead === false) {
					this.scene.gameState.bookRead = true;
				}
				break;
			case Objects.familyTree:
				this.scene.animationManager.playAnimation(Objects.familyTree, () => {
					this.scene.delayedExecute(1, () => {
						this.scene.GOContainer.fetchByName(Objects.familyTree, true, true);
					});
				}, () => {
					this.scene.singleDialogLine(Texts.tutorialFamilyTreeFound, '05-tutorialFamilyTreeFound', 0, () => {
						this.interact(Objects.familyTreeLocated);
					});
				});
				break;
			case Objects.familyTreeLocated:
				this.scene.familyTreeManager.show();
				if (this.scene.gameState.familyTreeRead === false) {
					this.scene.gameState.familyTreeRead = true;
				}
				break;
			case Objects.clock:
				const clock = this.scene.GOContainer.fetchByName(Objects.clock);
				clock.disableInteractive();
				clock.clearTint();
				this.scene.singleDialogLine(Texts.tutorialClockRunning, '07-tutorialClockRunning', 0);
				this.scene.animationManager.playAnimation(Objects.clock, () => {
					this.scene.animationManager.clockMovement.setVisible(false);
				}, () => {
					this.scene.UITimer.updateTimer();
					this.scene.gameState.clockRunning = true;
					this.scene.animationManager.clockMovement.setVisible(true);
				});
				break;
			case Objects.photograph:
				this.scene.animationManager.playAnimation(Objects.photograph, () => {
					this.scene.delayedExecute(1.2, () => {
						this.scene.GOContainer.fetchByName(Objects.photograph, true, true);
					});
				}, () => {
					this.interact(Objects.photographLocated);
				});
				break;
			case Objects.photographLocated:
				this.scene.photographDetail.show();
				if (this.scene.gameState.photographRead === false) {
					this.scene.gameState.photographRead = true;
				}
				break;
			case Objects.id:
				this.scene.hat.setVisible(false);
				this.scene.animationManager.playAnimation(Objects.id, () => {
					this.scene.GOContainer.fetchByName(Objects.id, true, true);
				}, () => {
					this.interact(Objects.idLocated);
					this.scene.hat.setVisible(true);
				});
				break;
			case Objects.idLocated:
				this.scene.idDetail.show();
				if (this.scene.gameState.idRead === false) {
					this.scene.gameState.idRead = true;
				}
				break;
			case Objects.letter:
				this.scene.animationManager.playAnimation(Objects.letter, () => {
					this.scene.GOContainer.fetchByName(Objects.letter, true, true);
				}, () => {
					this.interact(Objects.letterLocated);
				});
				break;
			case Objects.letterLocated:
				this.scene.letterDetail.show();
				if (this.scene.gameState.letterRead === false) {
					this.scene.gameState.letterRead = true;
				}
				break;
			case Objects.blackmail:
				this.scene.animationManager.playAnimation(Objects.blackmailLocated, () => {
					this.scene.GOContainer.fetchByName(Objects.blackmail, true, true);
				}, () => {
					this.interact(Objects.blackmailLocated);
				});
				break;
			case Objects.blackmailLocated:
				this.scene.blackmailDetail.show();
				if (this.scene.gameState.blackmailRead === false) {
					this.scene.gameState.blackmailRead = true;
				}
				break;
			case Objects.plug:
				this.scene.animationManager.playAnimation(Objects.plug, () => {
					this.scene.GOContainer.fetchByName(Objects.plug, true, true);
				}, () => {
					this.scene.gameState.computerStarted = true;
					this.scene.gameState.computerLoading = true;
					this.scene.objectsManager.reRender();
					this.scene.singleDialogLine(Texts.computerStarted, '11-computerStarted', 0);
					setTimeout(() => {
						this.scene.gameState.computerLoading = false;
						this.scene.objectsManager.reRender();
					}, 1300);
				});
				break;
			case Objects.computerLocated:
				if (this.scene.gameState.replyPrinted === true) {
					this.scene.singleDialogLine(Texts.computerReplyAlreadyRead, '30-computerReplyAlreadyRead', 0);
				} else if (this.scene.gameState.replyReceived === true && this.scene.gameState.replyPrinted === false) {
					this.scene.singleDialogLine(Texts.computerReplyRead, '29-computerReplyRead', 0, () => {
						this.scene.animationManager.printReply(() => {
							this.scene.gameState.replyPrinted = true;
							this.scene.objectsManager.reRender();
						});
					});
				} else if (
					this.scene.gameState.mailSent === false
					&& this.scene.gameState.businessCardRead === true
					&& this.scene.gameState.newspaperRead === true
				) {
					this.scene.animationManager.playAnimation(Objects.computerLocatedMail, null, () => {
						this.scene.computerDetail.show();
						if (this.scene.gameState.computerRead2 === false) {
							this.scene.gameState.computerRead2 = true;
						}
					});
				} else if (this.scene.gameState.mailSent === true && this.scene.gameState.replyReceived === false) {
					this.scene.singleDialogLine(Texts.computerWaitingForAnswer, '27-computerWaitingForAnswer', 0);
				} else if (this.scene.gameState.computerLoggedIn === true) {
					this.scene.singleDialogLine(Texts.computerUpdating, '14-computerUpdating', 0);
				} else {
					this.scene.animationManager.playAnimation(Objects.computerLocated, null, () => {
						this.scene.computerDetail.show();
						if (this.scene.gameState.computerRead === false) {
							this.scene.gameState.computerRead = true;
							this.scene.singleDialogLine(Texts.computerRead, '12-computerRead', 0);
						}
					});
				}
				break;
			case Objects.mainGoal:
				this.scene.mainGoalDetail.show();
				if (this.scene.finalQuestion !== true) {
					this.scene.singleDialogLine(Texts.firstMainGoalClicked1, '16-firstMainGoalClicked1', 0);
				}
				break;
			case Objects.translation:
				this.scene.GOContainer.fetchByName(Objects.translation, true, true);
				this.interact(Objects.translationLocated);
				break;
			case Objects.translationLocated:
				this.scene.translationDetail.show();
				if (this.scene.gameState.translationRead === false) {
					this.scene.gameState.translationRead = true;
				}
				break;
			case Objects.bookPage2:
				this.scene.GOContainer.fetchByName(Objects.bookPage2, true, true);
				this.scene.gameState.bookPage2Found = true;
				this.interact(Objects.bookLocated);
				this.scene.bookManager.selectPage(this.scene.bookManager.page2);
				break;
			case Objects.mail:
				this.scene.GOContainer.fetchByName(Objects.mail, true, true);
				this.interact(Objects.mailLocated);
				break;
			case Objects.mailLocated:
				this.scene.mailDetail.show();
				if (this.scene.gameState.mailRead === false) {
					this.scene.gameState.mailRead = true;
				}
				break;
			case Objects.businessCard:
				this.scene.animationManager.playAnimation(Objects.businessCard, () => {
					this.scene.delayedExecute(1, () => {
						this.scene.GOContainer.fetchByName(Objects.businessCard, true, true);
					});
				}, () => {
					this.scene.animationManager.dogIdle.setVisible(true);
					this.interact(Objects.businessCardLocated);
				});
				break;
			case Objects.businessCardLocated:
				this.scene.businessCardDetail.show();
				if (this.scene.gameState.businessCardRead === false) {
					this.scene.gameState.businessCardRead = true;
				}
				break;
			case Objects.newspaper:
				this.scene.animationManager.playAnimation(Objects.newspaper, () => {
					this.scene.GOContainer.fetchByName(Objects.newspaper, true, true);
				}, () => {
					this.scene.singleDialogLine(Texts.secondNewspaperFound, '21-secondNewspaperFound', 0, () => {
						this.interact(Objects.newspaperLocated);
					});
				});
				break;
			case Objects.newspaperLocated:
				this.scene.newspaperDetail.show();
				if (this.scene.gameState.newspaperRead === false) {
					this.scene.gameState.newspaperRead = true;
				}
				break;
			case Objects.trapdoor:
				this.scene.animationManager.playAnimation(Objects.trapdoor, () => {
					this.scene.GOContainer.fetchByName(Objects.trapdoor, true, true);
				}, () => {
					this.scene.gameState.trapdoorClicked = true;
					this.scene.objectsManager.reRender();
					this.scene.singleDialogLine(Texts.secondTrapdoor, '22-secondTrapdoor', 0);
				});
				break;
			case Objects.bookPage3:
				this.scene.GOContainer.fetchByName(Objects.bookPage3, true, true);
				this.scene.gameState.bookPage3Found = true;
				this.interact(Objects.bookLocated);
				this.scene.bookManager.selectPage(this.scene.bookManager.page3);
				break;
			case Objects.diary:
				this.scene.animationManager.playAnimation(Objects.diary, () => {
					this.scene.GOContainer.fetchByName(Objects.diary, true, true);
				}, () => {
					this.interact(Objects.diaryLocated);
				});
				break;
			case Objects.diaryLocated:
				this.scene.diaryDetail.show();
				if (this.scene.gameState.diaryRead === false) {
					this.scene.gameState.diaryRead = true;
					this.scene.singleDialogLine(Texts.secondDiaryRead, '23-secondDiaryRead', 0);
				}
				break;
			case Objects.reply:
				this.scene.GOContainer.fetchByName(Objects.reply, true, true);
				this.interact(Objects.replyLocated);
				break;
			case Objects.replyLocated:
				this.scene.replyDetail.show();
				if (this.scene.gameState.replyRead === false) {
					this.scene.gameState.replyRead = true;
				}
				break;
			case Objects.conversation:
				this.scene.GOContainer.fetchByName(Objects.conversation, true, true);
				this.interact(Objects.conversationLocated);
				break;
			case Objects.conversationLocated:
				this.scene.conversationDetail.show();
				if (this.scene.gameState.conversationRead === false) {
					this.scene.gameState.conversationRead = true;
				}
				break;
			case Objects.bookPage4:
				this.scene.GOContainer.fetchByName(Objects.bookPage4, true, true);
				this.scene.gameState.bookPage4Found = true;
				this.interact(Objects.bookLocated);
				this.scene.bookManager.selectPage(this.scene.bookManager.page4);
				break;
			default:
				break;
		}
	}
}

export default InteractHandler;
